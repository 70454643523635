import {
    RolePermission, UsersPermissions, InstallationsPermissions, ContactsPermissions,
    UserHistoryPermissions, DevicesPermissions, ComunicationDevicesPermissions, ContractsPermissions, HousingPlanDocumentsPermissions, ProfessionalPermissions
} from "./permisions.model"

export default new RolePermission([
    new UsersPermissions({ enabled: true, list: true, create: false, edit: true, show: false, del: false }),
    new ContactsPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new UserHistoryPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new DevicesPermissions({enabled: true, list: true, create: true, edit: true, show: false, del: false}),
    new ComunicationDevicesPermissions({enabled: true, list: true, create: true, edit: true, show: false, del: false}),
    new InstallationsPermissions({enabled: true, list: true, create: true, edit: true, show: false, del: false}),
    new ContractsPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),
    new HousingPlanDocumentsPermissions({ enabled: true, list: true, create: true, edit: true, show: true, del: true }),
    new ProfessionalPermissions({enabled: true, list: false, create: false, edit: false, show: false, del: false}),

])