import { AutocompleteArrayInput, ArrayInput, SimpleFormIterator, AutocompleteInput, DateInput,DateTimeInput, Create, Datagrid, Edit, Filter, FormTab, List, ReferenceInput, ReferenceManyField, Show, TabbedForm, TextField, FunctionField, TextInput, TabbedFormTabs, Button, SelectInput, BooleanInput, DateField } from "ra-ui-materialui";
import { useCallback, useEffect, useState } from "react";
import { useFormState } from "react-final-form";
import { getProvinces, getCountries, getLanguajes, getLikes, /*getClientType,*/ getWorkingCenter, getGender, getDependencyDegree, getTherapeuticProfile, getTechnologicalIndependence, getUsesTechHelp, getCivilStatus, getProfession } from '../services/master-tables.service'
import TownProvider from "../provider/town.provider";
import './resources.css'
import { email, maxValue, number, required, useMutation, useNotify, useRedirect, useGetIdentity, FormDataConsumer, maxLength } from "ra-core";
import { CustomToolbarPermissions, PostBulkActionButtonsPermision } from "./utils";
import { ChipArrayUseStyles, TabsUseStyles } from "../App";
import { transformUserHistory, UserHistoryView } from './userHistory'
import { UserInteractionsList } from './userInteractions'
import { UserDocumentsList } from './userDocuments'
import { EditContactsButton, DeleteContactsButton, AddNewContactsButton } from './contacts'
import { usePermissions, ImageInput, ImageField, downloadCSV } from "react-admin";
import { ProvinceField } from "../components/Fields/ProvinceField"
import { TheraPeuticalProfilesField } from "../components/Fields/TheraPeuticalProfilesField"
import { DependencyDegreeField } from "../components/Fields/DependencyDegreeField"
import { ContactTypeField } from "../components/Fields/ContactTypeField";
import { TextField as MUITextField, Button as MUIButton } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import {
    AerialClient, ClientClient, SleepSummaryQueryDto, ActivitySummaryQueryDto, ActivityPredictionQueryDto, SleepPredictionQueryDto,// ,MotionQueryDto 
    LoginClient
} from "../services/ohl.virtualCenter.api";
import moment from 'moment-timezone';
import 'moment-timezone/node_modules/moment/locale/es';
import Box from '@material-ui/core/Box';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser';
import { useRecordContext } from 'react-admin';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import { Bar, XAxis, YAxis, Cell, ResponsiveContainer, Tooltip, ComposedChart, Area } from 'recharts';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import '../css/user.css';
import { saveAs } from 'file-saver';
import { makeStyles } from '@material-ui/core/styles';
import jsonExport from 'jsonexport/dist';
import {HousingPlanDocumentsList} from './housingPlanDocuments'
import {BooleanInputCustom} from '../components/BooleanInputCustom';

moment.locale('es');
moment.localeData('es');

const aerialClient = new AerialClient(process?.env?.REACT_APP_PUBLIC_API);
const loginClient = new LoginClient(process?.env?.REACT_APP_PUBLIC_API);
const clientClient = new ClientClient(process?.env?.REACT_APP_PUBLIC_API);

var XLSX = require("xlsx");

const validateUserCreation = (values) => {
    const errors = {};
    if (!values?.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values?.identificationNumber && !validarDNIoNIE(values?.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values?.name?.trim()) {
        errors.name = 'Requerido';
    }
    if (!values?.surnames?.trim()) {
        errors.surnames = 'Requerido';
    }
    if (!values?.password?.trim()) {
        errors.password = 'Requerido';
    }
    if (!values?.activeDate) {
        errors.activeDate = 'Requerido';
    }
    if (!values?.countryId) {
        errors.countryId = 'Requerido';
    }
    if (!values?.cellPhone?.trim()) {
        errors.cellPhone = 'Requerido';
    }
    if (!values?.postalCode?.trim()) {
        errors.postalCode = 'Requerido';
    } 
    if (!values?.townId) {
        errors.townId = 'Requerido';
    }
    if (!values?.provinceId) {
        errors.provinceId = 'Requerido';
    }
    if (!values?.address?.trim()) {
        errors.address = 'Requerido';
    }
    if (!values.dependencyDegreeId) {
        errors.dependencyDegreeId = 'Requerido';
    }
    if (!values?.therapeuticalProfiles) {
        errors.therapeuticalProfiles = 'Requerido';
    }
    if (values?.leavingDate && values?.activeDate && moment(values?.leavingDate).isBefore(values?.activeDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de alta';
    } 
    if (values?.leavingDate && values?.inactivityDate && moment(values?.leavingDate).isBefore(values?.inactivityDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de inactividad';
    }
    if (values?.inactivityDate && values?.activeDate && moment(values?.inactivityDate).isBefore(values?.activeDate)) {
        errors.inactivityDate = 'La fecha de inactividad no puede ser anterior a la fecha de alta';
    }
    if (values?.inactivityDate && values?.leavingDate && moment(values?.inactivityDate).isAfter(values?.leavingDate)) {
        errors.inactivityDate = 'La fecha de inactividad no puede ser posterior a la fecha de baja';
    }
    if (!values?.contracts?.length) {
        errors.contracts = 'Requerido';
        errors.contractsValidation = 'Requerido';
        errors.supportProfessional = { id: 'Debe seleccionar al menos un contrato para seleccionar el profesional' };
        errors.referenceSocialWorker = { id: 'Debe seleccionar al menos un contrato para seleccionar el profesional' };
    } else {
        if (!values?.supportProfessional?.id) {
            errors.supportProfessional = { id: 'Requerido' };
        }
        if (!values?.referenceSocialWorker?.id) {
            errors.referenceSocialWorker = { id: 'Requerido' };
        }
    }
    if(values?.birthdate && values?.birthdate < '1900-01-01') {
        errors.birthDate = 'La fecha debe ser al menos el 01/01/1900';
    }
    if(values?.birhtdate && values?.birthdate > new Date()) {
        errors.birthDate = 'La fecha de nacimiento no puede ser futura';
    }
    if(values?.contracts?.length && !values?.contracts?.some(c => c?.isMain)){
        errors.contracts = values.contracts.map((contract, index) => ({
            ...errors.contracts?.[index],
            isMain: 'Seleccione un contrato principal',
          }));
        errors.mainContractId = 'Requerido';
        errors.contractsValidation = 'Requerido';
    }
    return errors
};

const validarDNIoNIE = (dni) => {
    var numero, letr, letra;
    var expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    dni = dni?.toUpperCase();

    if (expresion_regular_dni.test(dni) === true) {
        numero = dni?.substr(0, dni?.length - 1);
        numero = numero?.replace('X', 0);
        numero = numero?.replace('Y', 1);
        numero = numero?.replace('Z', 2);
        letr = dni?.substr(dni?.length - 1, 1);
        numero = numero % 23;
        letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
        letra = letra?.substring(numero, numero + 1);
        if (letra !== letr) {
            //alert('Dni erroneo, la letra del NIF no se corresponde');
            return false;
        } else {
            //alert('Dni correcto');
            return true;
        }
    } else {
        //alert('Dni erroneo, formato no válido');
        return false;
    }
}

const validateUserEdit = (values) => {
    const errors = {};
    if (!values?.identificationNumber) {
        errors.identificationNumber = 'Requerido';
    }
    if (values?.identificationNumber && !validarDNIoNIE(values?.identificationNumber)) {
        errors.identificationNumber = 'No es un NIF/NIE válido';
    }
    if (!values?.name) {
        errors.name = 'Requerido';
    }
    if (!values?.surnames) {
        errors.surnames = 'Requerido';
    }
    if (!values?.activeDate) {
        errors.activeDate = 'Requerido';
    }
    if (!values?.countryId) {
        errors.countryId = 'Requerido';
    }
    if (!values?.cellPhone?.trim()) {
        errors.cellPhone = 'Requerido';
    }
    if (!values?.postalCode?.trim()) {
        errors.postalCode = 'Requerido';
    } 
    if (!values?.townId) {
        errors.townId = 'Requerido';
    }
    if (!values?.provinceId) {
        errors.provinceId = 'Requerido';
    }
    if (!values?.address?.trim()) {
        errors.address = 'Requerido';
    }
    if (!values?.dependencyDegreeId) {
        errors.dependencyDegreeId = 'Requerido';
    }
    if (!values?.therapeuticalProfiles || values?.therapeuticalProfiles?.length === 0) {
        errors.therapeuticalProfiles = 'Requerido';
    }
    if (values?.leavingDate && values?.activeDate && moment(values?.leavingDate).isBefore(values?.activeDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de alta';
    }

    if (values?.leavingDate && values?.inactivityDate && moment(values?.leavingDate).isBefore(values?.inactivityDate)) {
        errors.leavingDate = 'La fecha de baja no puede ser anterior a la fecha de inactividad';
    }

    if (values?.inactivityDate && values?.activeDate && moment(values?.inactivityDate).isBefore(values?.activeDate)) {
        errors.inactivityDate = 'La fecha de inactividad no puede ser anterior a la fecha de alta';
    }
    if (values?.inactivityDate && values?.leavingDate && moment(values?.inactivityDate).isAfter(values?.leavingDate)) {
        errors.inactivityDate = 'La fecha de inactividad no puede ser posterior a la fecha de baja';
    }
    if (!values?.contracts?.length) {
        errors.contracts = 'Requerido';
        errors.contractsValidation = 'Requerido';
        errors.supportProfessional = { id: 'Debe seleccionar al menos un contrato para seleccionar el profesional' };
        errors.referenceSocialWorker = { id: 'Debe seleccionar al menos un contrato para seleccionar el profesional' };
    } else {
        if (!values?.supportProfessional?.id) {
            errors.supportProfessional = { id: 'Requerido' };
        }
        if (!values?.referenceSocialWorker?.id) {
            errors.referenceSocialWorker = { id: 'Requerido' };
        }
    }
    if(values?.birthDate && values?.birthDate < '1900-01-01') {
        errors.birthDate = 'La fecha debe ser al menos el 01/01/1900';
    }
    if(values?.birthDate && values?.birthDate > new Date()) {
        errors.birthDate = 'La fecha de nacimiento no puede ser futura';
    }
    if(values?.contracts?.length && !values?.contracts?.some(c => c?.isMain)){
        errors.contracts = values.contracts.map((contract, index) => ({
            ...errors.contracts?.[index],
            isMain: 'Seleccione un contrato principal',
          }));
        errors.mainContractId = 'Requerido';
        errors.contractsValidation = 'Requerido';
    }

    return errors
};

const UserTitle = ({ record }) => {
    return <span>Usuario {record ? `${record?.name} ${record?.surnames}` : ''}</span>;
};

const UserFilter = (props) => {
    const [provinceList, setprovinceList] = useState()
    const [therapeuticalProfilesList, settherapeuticalProfilesList] = useState()
    const [dependencyDegreeList, setdependencyDegreeList] = useState()
    const chipClasses = ChipArrayUseStyles();

    useEffect(() => {
        getProvinces().then(
            res => {
                setprovinceList(res?.elements);
            }
        )
        getTherapeuticProfile().then(
            res => {
                settherapeuticalProfilesList(res?.elements);
            }
        )
        getDependencyDegree().then(
            res => {
                setdependencyDegreeList(res?.elements);
            }
        )
    }, []);
    return (
        <Filter {...props}>
            <TextInput label="NIF/NIE" source="identificationNumber" alwaysOn />
            <TextInput label="Usuario" source="name" alwaysOn />
            <SelectInput label="Estado" source="active" alwaysOn choices={[
                { id: 'all', name: 'Todos' },
                { id: 'ACTIVE', name: 'Activo' },
                { id: 'INACTIVE', name: 'Inactivo' },
                { id: 'LEAVE', name: 'Baja' }, 
            ]} />
            <TextInput label="Id" source="id" defaultValue="" />
            <ReferenceInput
                    label="Contrato Principal"
                    source="contractId"
                    reference="contracts"
                    perPage={1000}
                    resettable={true}
                >
                    <AutocompleteInput  optionText={(record) => record?.contractNumber}/>
                </ReferenceInput>
            {
                provinceList ?
                    <AutocompleteInput
                        label="Provincia"
                        source="provinceId"
                        choices={provinceList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            {
                therapeuticalProfilesList ?
                    <AutocompleteArrayInput
                        label="Perfil Asistencial"
                        source="TherapeuticalProfilesId"
                        choices={therapeuticalProfilesList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        className="full"
                        classes={chipClasses}
                        allowEmpty={false}
                    />
                    : null
            }
            {
                dependencyDegreeList ?
                    <AutocompleteInput
                        label="Grado Dependencia"
                        source="dependencyDegreeId"
                        choices={dependencyDegreeList}
                        optionText="value"
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    />
                    : null
            }
            <DateInput label="Fecha Alta" source="activeDate" validate={[validateCustomDate('Error de Formato')]} />
            <DateInput label="Fecha Baja" source="leavingDate" validate={[validateCustomDate('Error de Formato')]} />
        </Filter>
    )
};


export const validateCustomDate = (message) => {
    return (value) => {
        if(esFechaValida(value)) {
            return undefined; // Sin errores, validación pasa
        } else {
            return message; // Retorna el mensaje de error personalizado
        }
    };
};

function esFechaValida(fechaStr) {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(fechaStr)) {
      return false;
    }
    const [year, month, day] = fechaStr.split('-').map(Number);

    // Validar rango de años razonable
    if (year < 1900 || year > 2100) {
        return false;
    }
    const date = new Date(year, month - 1, day);
  
    if (date.getFullYear() !== year || date.getMonth() + 1 !== month || date.getDate() !== day) {
      return false;
    }
  
    return true;
  }


export const UsuarioList = props => {
    const useStyles = makeStyles((theme) => ({
        paper: {
            border: '1px solid',
            padding: theme.spacing(1),
            backgroundColor: theme?.palette?.background?.paper,
        },
    }));
    const [usersList, setUsersList] = useState();
    const [workingCenterList, setWorkingCenterList] = useState();
    const [provincesList, setProvincesList] = useState();
    const [therapeuticalProfilesList, setTherapeuticalProfilesList] = useState();
    const [genderList, setGenderList] = useState();
    const [civilStatusList, setCivilStatusList] = useState();
    const [professionList, setProfessionList] = useState();
    const [dependencyDegreeList, setDependencyDegreeList] = useState();
    const [technologicalIndependenceList, setTechnologicalIndependenceList] = useState();
    const [usesTechHelpList, setUsesTechHelpList] = useState();

    const classes = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const { identity, loading } = useGetIdentity();

    useEffect(() => {
        getWorkingCenter().then(
            res => {
                setWorkingCenterList(res.elements);
            }
        )
        getProvinces().then(
            res => {
                setProvincesList(res.elements);
            }
        )
        getTherapeuticProfile().then(
            res => {
                setTherapeuticalProfilesList(res.elements);
            }
        )
        getGender().then(
            res => {
                setGenderList(res.elements);
            }
        )
        getCivilStatus().then(
            res => {
                setCivilStatusList(res.elements);
            }
        )
        getProfession().then(
            res => {
                setProfessionList(res.elements);
            }
        )
        getDependencyDegree().then(
            res => {
                setDependencyDegreeList(res.elements);
            }
        )
        getTechnologicalIndependence().then(
            res => {
                setTechnologicalIndependenceList(res.elements);
            }
        )
        getUsesTechHelp().then(
            res => {
                setUsesTechHelpList(res.elements);
            }
        )
    }, []);
    if (!props.permissions) return null


    const exporter = users => {
        setIsOpen(false);
        let usersListAux = users?.map(post => {
            const {  isDeleted, androidDevice, communicationDevice, viewPassword, clientExternalPartners, picture, address2, clientLikes,
                clientTypeId, homePhone, city, countryId, postalCode, address, languages, sharepointFolder, supportProfessional, referenceSocialWorker, contracts, 
                // therapeuticalProfiles, birthDate, activeDate, leavingDate, setupDate, genderId, dependencyDegreeId, technologicalIndependenceId, usesTechHelpId, whichOnesTechHelp, active,
                ...userElement } = post;
                
            if (userElement?.associatedWorkingCenterId) {
                let workingCenter = workingCenterList?.filter(workingcenter => workingcenter?.id === userElement?.associatedWorkingCenterId);
                if (workingCenter && workingCenter?.length > 0) {
                    userElement.associatedWorkingCenterId = workingCenter[0]?.value;
                }
            }
            if (userElement?.provinceId) {
                let provinces = provincesList?.filter(province => province?.id === userElement?.provinceId);
                if (provinces && provinces?.length > 0) {
                    userElement.provinceId = provinces[0]?.value;
                }
            }
            if (userElement?.therapeuticalProfiles) {
                let profiles = therapeuticalProfilesList?.filter(therapeuticalProfile => 
                    userElement.therapeuticalProfiles.includes(therapeuticalProfile?.id)
                );
                
                let profileValues = profiles.map(profile => profile?.value);
                
                let profilesString = profileValues.join(",");
                
                userElement.therapeuticalProfiles = profilesString;
            }
            if (userElement?.genderId) {
                let genders = genderList?.filter(gender => gender?.id === userElement?.genderId);
                if (genders && genders?.length > 0) {
                    userElement.genderId = genders[0]?.value;
                }
            }
            if (userElement?.civilStatusId) {
                let civilStatuses = civilStatusList?.filter(civilStatus => civilStatus?.id === userElement?.civilStatusId);
                if (civilStatuses && civilStatuses?.length > 0) {
                    userElement.civilStatusId = civilStatuses[0]?.value;
                }
            }
            if (userElement?.professionId) {
                let professions = professionList?.filter(profession => profession?.id === userElement?.professionId);
                if (professions && professions?.length > 0) {
                    userElement.professionId = professions[0]?.value;
                }
            }
            if (userElement?.dependencyDegreeId) {
                let dependencyDegrees = dependencyDegreeList?.filter(dependencyDegree => dependencyDegree?.id === userElement?.dependencyDegreeId);
                if (dependencyDegrees && dependencyDegrees?.length > 0) {
                    userElement.dependencyDegreeId = dependencyDegrees[0]?.value;
                }
            }
            if (userElement?.technologicalIndependenceId) {
                let technologicalIndependences = technologicalIndependenceList?.filter(technologicalIndependence => technologicalIndependence?.id === userElement?.technologicalIndependenceId);
                if (technologicalIndependences && technologicalIndependences?.length > 0) {
                    userElement.technologicalIndependenceId = technologicalIndependences[0]?.value;
                }
            }
            if (userElement?.usesTechHelpId) {
                let usesTechHelps = usesTechHelpList?.filter(usesTechHelp => usesTechHelp?.id === userElement?.usesTechHelpId);
                if (usesTechHelps && usesTechHelps?.length > 0) {
                    userElement.usesTechHelpId = usesTechHelps[0]?.value;
                }
            }
            if (userElement?.active) {
                userElement.active = userElement.active === 'ACTIVE' ? 'ACTIVO' : userElement.active === 'INACTIVE' ? 'INACTIVO': 'BAJA'
            }
            userElement.isUsingAerial = userElement.isUsingAerial === true ? 'SI' :'NO'
            
            if (contracts && contracts.length > 0) {
                
                let contractValues = contracts.map(contract => contract?.contractNumber);
                
                let contractString = contractValues.join(",");
                
                userElement.contracts = contractString;
            }
            return userElement;
        });
        usersListAux = translateHeaders(usersListAux);
        setUsersList(usersListAux);
        const xlsxBlob = arrayToXlsx(usersListAux);
        saveAs(xlsxBlob, 'usuarios.xlsx');
    };

    const saveExcelFile = () => {
        const xlsxBlob = arrayToXlsx(usersList);
        saveAs(xlsxBlob, 'usuarios.xlsx');
    };


    const saveCsvFile = () => {
        jsonExport(usersList, {
            headers: ['NIF/NIE', 'Nombre', 'Apellidos', 'Email', 'Provincia', 'Tfno. Móvil', 'Centro Trabajo'] // order fields in the export
        }, (err, csv) => {
            downloadCSV(csv, 'usuarios'); // download as 'posts.csv` file
        });
    };

    const arrayToXlsx = (array) => {
        const worksheet = XLSX.utils.json_to_sheet(array);
        worksheet["!cols"] = [{ wch: 12 }, { wch: 24 }, { wch: 24 }, { wch: 32 }, { wch: 12 }, { wch: 12 }, { wch: 12 }];
        const workbook = XLSX?.utils?.book_new();
        XLSX?.utils?.book_append_sheet(workbook, worksheet, 'Sheet1');
        const xlsxBlob = XLSX?.write(workbook, {
            bookType: 'xlsx',
            type: 'array',
        });
        return new Blob([xlsxBlob], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
    };
    const translateHeaders = (usersList) => {
        for (let i = 0; i < usersList?.length; i++) {
            usersList[i]['Id'] = usersList[i]?.['id'];
            delete usersList[i]?.['id'];
            usersList[i]['NIF/NIE'] = usersList[i]?.['identificationNumber'];
            delete usersList[i]?.['identificationNumber'];
            usersList[i]['Nombre'] = usersList[i]?.['name'];
            delete usersList[i]?.['name'];
            usersList[i]['Apellidos'] = usersList[i]?.['surnames'];
            delete usersList[i]['surnames'];
            usersList[i]['Contrato Principal'] = usersList[i]?.['contracts'];
            delete usersList[i]['contracts'];
            usersList[i]['Email'] = usersList[i]?.['email'];
            delete usersList[i]['email'];
            usersList[i]['Provincia'] = usersList[i]?.['provinceId'];
            delete usersList[i]['provinceId'];
            usersList[i]['Tfno. Móvil'] = usersList[i]?.['cellPhone'];
            delete usersList[i]?.['cellPhone'];
            usersList[i]['Centro Trabajo'] = usersList[i]?.['associatedWorkingCenterId'];
            delete usersList[i]?.['associatedWorkingCenterId'];
            usersList[i]['Versión app Vera'] = usersList[i]?.['veraAppVersion'];
            delete usersList[i]?.['veraAppVersion'];
            usersList[i]['Fecha versión app Vera'] = usersList[i]?.['veraAppVersionDate']?moment(usersList[i]?.['veraAppVersionDate']).format('DD-MM-YYYY HH:mm:ss'): '';
            delete usersList[i]?.['veraAppVersionDate'];
            usersList[i]['Perfil terapéutico'] = usersList[i]?.['therapeuticalProfiles'];
            delete usersList[i]?.['therapeuticalProfiles'];
            usersList[i]['Fecha de nacimiento'] = usersList[i]?.['birthDate']?moment(usersList[i]?.['birthDate']).format('DD-MM-YYYY'): '';
            delete usersList[i]?.['birthDate'];
            usersList[i]['Fecha de alta'] = usersList[i]?.['activeDate']?moment(usersList[i]?.['activeDate']).format('DD-MM-YYYY'): '';
            delete usersList[i]?.['activeDate'];
            usersList[i]['Fecha de baja'] = usersList[i]?.['leavingDate']?moment(usersList[i]?.['leavingDate']).format('DD-MM-YYYY'): '';
            delete usersList[i]?.['leavingDate'];
            usersList[i]['Fecha de instalación'] = usersList[i]?.['setupDate']?moment(usersList[i]?.['setupDate']).format('DD-MM-YYYY'): '';
            delete usersList[i]?.['setupDate'];
            usersList[i]['Fecha de inactividad'] = usersList[i]?.['inactivityDate']?moment(usersList[i]?.['inactivityDate']).format('DD-MM-YYYY'): '';
            delete usersList[i]?.['inactivityDate'];
            usersList[i]['Sexo'] = usersList[i]?.['genderId'];
            delete usersList[i]?.['genderId'];
            usersList[i]['Estado Civil'] = usersList[i]?.['civilStatusId'];
            delete usersList[i]?.['civilStatusId'];
            usersList[i]['Profesión'] = usersList[i]?.['professionId'];
            delete usersList[i]?.['professionId'];
            usersList[i]['Autonomía a la conexión'] = usersList[i]?.['technologicalIndependenceId'];
            delete usersList[i]?.['technologicalIndependenceId'];
            usersList[i]['Grado de dependencia'] = usersList[i]?.['dependencyDegreeId'];
            delete usersList[i]?.['dependencyDegreeId'];
            usersList[i]['Utiliza ayudas técnicas'] = usersList[i]?.['usesTechHelpId'];
            delete usersList[i]?.['usesTechHelpId'];
            usersList[i]['Cuáles'] = usersList[i]?.['whichOnesTechHelp'];
            delete usersList[i]?.['whichOnesTechHelp'];
            usersList[i]['Estado'] = usersList[i]?.['active'];
            delete usersList[i]?.['active'];
            usersList[i]['Usa Aerial'] = usersList[i]?.['isUsingAerial'];
            delete usersList[i]?.['isUsingAerial'];
            delete usersList[i]?.['postCodeId'];
            delete usersList[i]?.['townId'];    
            delete usersList[i]?.['mainContractId'];    
            delete usersList[i]?.['contractLogoId'];    
            delete usersList[i]?.['alias'];    
        }
        return usersList;
    }

    if (loading && !identity) {
        return <div></div>;
    }
    return (
        <>
         {/* <div onClick={() => setIsOpen(false)}> */}
            <List bulkActionButtons={props?.permissions?.users?.delete ? <PostBulkActionButtonsPermision perm="users.delete" /> : false} {...props} filters={< UserFilter />} filterDefaultValues={{ active: "ACTIVE", isMain:true }}
                sort={{ field: 'Name', order: 'DESC' }} exporter={identity.roles === "CONTRACTING_AUTHORITY" ? false : (users, event) => exporter(users, event)}>
                <Datagrid rowClick={props?.permissions?.users?.edit ? "edit" : "show"} isRowSelectable={record => props?.permissions?.users?.delete}>
                    <TextField label="Id" source="id" />
                    <FullNameField label="Usuario" sortBy="Name"/>
                    <FunctionField label="Contrato Principal" sortBy="Contracts.ContractNumber"                          
                        render={record => {
                            let arr = record?.contracts?.filter(p => p?.isMain)?.map(p => p?.contractNumber)
                            return ReactHtmlParser(arr?.slice(0, 3).join('<br>') + (arr?.length > 3 ? '...' : ''))
                        }}
                    />
                    <ProvinceField label="Provincia" source="provinceId" sortBy="Province.Name" sortable={true}/>
                    <FunctionField label="Estado" sortBy="Active" render={record => record?.active === 'ACTIVE' ? 
                        <Box component="span" style={{ backgroundColor: '#00ab8e', color: 'white', padding: '0.2em 0.5em', borderRadius: '0.8em' }}>
                            Activo
                        </Box> : record?.active === 'INACTIVE' ?
                        <Box component="span" style={{ backgroundColor: 'rgb(162 160 160 / 82%)', color: 'white', padding: '0.2em 0.5em', borderRadius: '0.8em' }}>
                            Inactivo
                        </Box> : 
                        <Box component="span" style={{ backgroundColor: 'rgb(242 32 32 / 63%)', color: 'white', padding: '0.2em 0.5em', borderRadius: '0.8em' }}>
                            Baja
                        </Box>
                    } />   
                    <TheraPeuticalProfilesField label="Perfil Asistencial" source="therapeuticalProfiles" sortBy="TherapeuticProfiles.Name" sortable={true}/>
                    <DependencyDegreeField label="Grado Dependencia" source="dependencyDegreeId" sortBy="DependencyDegree.Name" sortable={true}/>
                    <DateField label="Fecha Alta" source="activeDate" />
                    <DateField label="Fecha Baja" source="leavingDate" />
                </Datagrid>
            </List >
            {isOpen && (
                <div style={{ position: 'fixed', top: '6rem', right: '6rem', zIndex: 1000 }}
                    id="tooltip"
                    className={classes?.paper}
                >
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveExcelFile()}>Excel</MUIButton>
                    <MUIButton variant="outlined" color="secondary" onClick={() => saveCsvFile()}>CSV</MUIButton>
                    {/* <MUIButton variant="outlined" color="secondary" onClick={() => savePdfFile()}>PDF</MUIButton> */}
                </div>
            )}
            </>
    )
};

const FullNameField = (props) => (
    <FunctionField
        {...props}
        render={record => `${record.name} ${record.surnames}`}
    />
);


const convertirHorasAHorasYMinutos = (horasConDecimales) => {
    const horas = Math?.floor(horasConDecimales);
    const minutos = Math?.round((horasConDecimales % 1) * 60);
  
    const horasTexto = horas !== 1 ? `${horas} h` : '1 h';
    const minutosTexto = minutos !== 1 ? `${minutos} min` : '1 min';
  
    if (horas === 0) {
      return minutosTexto;
    } else if (minutos === 0) {
      return horasTexto;
    } else {
      return `${horasTexto} ${minutosTexto}`;
    }
}

const CustomTooltip = ({ active, payload, label }) => {
    let sleepDuration = 0;
    let interruptDuration = 0;

    if (active && payload && payload?.length) {
        return (
            <table className="custom-tooltip" style={{
                backgroundColor: 'white', border: "1px solid black", borderRadius: '0.75rem',
                padding: '0.25rem 1rem'
            }} >
                <tr className="label"><td>{`${label}`}</td></tr>
                <br></br>
                {payload?.map(element => {
                    let label2 = element?.dataKey;
                    if (label2.includes("sleep")) {
                        label2 = label2?.replace("sleep", "Descanso ")
                        sleepDuration += parseFloat(element?.value);
                    }
                    if (label2?.includes("interrupt")) {
                        label2 = label2?.replace("interrupt", "Interrupción ")
                        interruptDuration += parseFloat(element?.value);
                    }
                    if (label2 !== "predictionRangeLow" && label2 !== "predictionTopLine") {
                        return <tr><td className="content">{label2}:</td><td align="right"> {convertirHorasAHorasYMinutos(element?.value)}</td></tr>
                    } else {
                        return null;
                    }
                })}
                <br></br>
                <tr><td>Total descanso:</td><td align="right"> {convertirHorasAHorasYMinutos(sleepDuration)}</td></tr>
                <tr><td>Total interrupciones:</td><td align="right"> {convertirHorasAHorasYMinutos(interruptDuration)}</td></tr>
            </table >
        );
    }

    return null;
};


export const AerialTab = props => {
    const classes = TabsUseStyles();
    const notify = useNotify();
    var isDST = false;

    const [aerialSelection, setAerialSelection] = useState(1);
    const [sleepFromDate, setSleepFromDate] = useState(moment()?.add(-9, 'days')?.format("YYYY-MM-DD"));
    const [sleepToDate, setSleepToDate] = useState(moment()?.add(-1, 'days')?.format("YYYY-MM-DD"));
    const [sleepData, setSleepData] = useState([]);
    const [sleepDataChart, setSleepDataChart] = useState([]);
    const [rowsPerPageSleep, setRowsPerPageSleep] = useState(7);
    const [pageSleep, setPageSleep] = useState(0);
    const [showSleepTable, setShowSleepTable] = useState(true);
    const [sleepActiveTab, setSleepActiveTab] = useState(0);

    const [activityFromDate, setActivityFromDate] = useState(moment()?.add(-2, 'days')?.format("YYYY-MM-DD"));
    const [activityToDate, setActivityToDate] = useState(moment()?.add(-1, 'days')?.format("YYYY-MM-DD"));
    const [activityData, setActivityData] = useState([]);
    const [rowsPerPageActivity, setRowsPerPageActivity] = useState(15);
    const [pageActivity, setPageActivity] = useState(0);
    const [showActivityTable, setShowActivityTable] = useState(true);
    const [activityActiveTab, setActivityActiveTab] = useState(0);
    const [activityPeriodSelection, setActivityPeriodSelection] = useState('hourly');
    const [activityPeriodSelectionAux, setActivityPeriodSelectionAux] = useState('hourly');
    const [monthCalendarValue, setMonthCalendarValue] = useState([moment()?.add(-1, 'months')?.utc(), moment()?.utc()]);

    const changeMonthCalendarValue = (value) => {
        if(value[0] && value[1]) {
            setMonthCalendarValue([moment(value[0]), moment(value[1])]);
        }
    };

    const record = useRecordContext();

    const StyledTableCell = withStyles((theme) => ({
        head: {
            backgroundColor: '#2196f3',
            color: theme?.palette?.common?.white,
            fontSize: 18,
            padding: "7px 11px 7px 11px",
            gap: "10px",
            height: "40px",
            width: "250px"
        },
        body: {
            fontSize: 16,
            padding: "10px 16px 10px 16px",
            gap: "10px",
            height: "40px",
            width: "250px"
        },
    }))(TableCell);

    const StyledTableRow = withStyles((theme) => ({
        root: {
            '&:nth-of-type(odd)': {
                backgroundColor: theme?.palette?.action?.hover,
            },
        },
    }))(TableRow);

    const handleChangePage = (event, newPage) => {
        setPageSleep(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPageSleep(parseInt(event?.target?.value));
        setPageSleep(0);
    };

    const handleAerialSelection = (e) => {
        setAerialSelection(e?.target?.value);
    }

    const changeSleepFromDate = (e) => {
        setSleepFromDate(e?.target?.value);
    }

    const changeSleepToDate = (e) => {
        setSleepToDate(e?.target?.value);
    }

    const CustomActivityTooltip = ({ active, payload, label }) => {

        if (active && payload && payload?.length) {
            return (
                <table className="custom-tooltip" style={{
                    backgroundColor: 'white', border: "1px solid black", borderRadius: '0.75rem',
                    padding: '0.25rem 1rem'
                }} >
                    <tr className="label"><td>{`${label}`}</td></tr>
                    <br></br>
                    <tr><td>
                        Total actividad:</td><td align="right">{(activityPeriodSelection === "hourly"? payload[0]?.value + " mins": 
                        convertirHorasAHorasYMinutos(payload[0]?.value))}
                    </td></tr>
                </table >
            );
        }
    
        return null;
    };

    const sleepRequest = (e) => {
        if (!record?.clientExternalPartners || !record?.clientExternalPartners?.aerialGroupId) {
            return;
        }
        const query = new SleepSummaryQueryDto();
        query.fromDate = moment(sleepFromDate)?.format('yyyy-MM-DDTHH:mm:ss+00:00');
        query.toDate = moment(sleepToDate)?.add(1, 'days')?.format('yyyy-MM-DDTHH:mm:ss+00:00');
        query.groupId = record?.clientExternalPartners?.aerialGroupId;
        var chartJson = [];

        if ((moment(query?.toDate)).add(-1, 'days').isBefore(moment(query?.fromDate))) {
            notify('La fecha de fin no puede ser menor que la fecha de inicio', 'error')
            return;
        }

        aerialClient?.getSleepSummary(query).then(
            res => {
                if (res?.summaries) {
                    let sleepSummaries = res?.summaries;
                    sleepSummaries?.forEach(element => {
                        if (element?.sleepDuration != null && element?.sleepDuration?.value == null) {
                            element.sleepDuration.value = 0;
                        }
                        if (element?.nbInterruptions && element?.nbInterruptions?.value == null) {
                            element.nbInterruptions.value = 0;
                        }
                    })
                    setSleepData(sleepSummaries);

                    for (var m = moment(query?.fromDate); m.isSameOrBefore(moment(query?.toDate)?.add(2, 'days')); m?.add(1, 'days')) {
                        let elementChartJson = {};
                        elementChartJson.timestamp = m?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                        elementChartJson.day = m?.format('DD-MM');
                        elementChartJson.predictionDuration = 0;
                        elementChartJson.predictionRangeLow = 0;
                        elementChartJson.predictionRangeHigh = 0;
                        elementChartJson.predictionTopLine = 0;
                        chartJson.push(elementChartJson);
                        let highestNightSleep;
                        let highestNightSleepIndex;
                        let interruptNightSumm;
                        for (let i = 0; i < sleepSummaries?.length; i++) {
                            if (m.format('yyyy-MM-DD') === (moment(sleepSummaries[i]?.timestamp).utc()?.format('yyyy-MM-DD'))) {
                                let sleepCounter = 0;
                                let interruptCounter = 0;
                                elementChartJson.sleepDuration = parseFloat(sleepSummaries[i]?.sleepDuration?.value / 60)?.toFixed(2);
                                if (res?.sleepOrInterrupt[i]) {
                                    res?.sleepOrInterrupt[i]?.forEach(element => {
                                        if (element?.type === 0) {
                                            sleepCounter++;
                                            elementChartJson["sleep" + sleepCounter] = parseFloat(element?.time / 60)?.toFixed(2);
                                        } else {
                                            interruptCounter++;
                                            elementChartJson["interrupt" + interruptCounter] = parseFloat(element?.time / 60)?.toFixed(2);
                                        }
                                    });
                                }
                                if (elementChartJson?.sleep1) {
                                    highestNightSleep = elementChartJson?.sleep1;
                                    interruptNightSumm = 0;
                                    for (let i = 1; i <= 10; i++) {
                                        if (elementChartJson?.["sleep" + i]) {
                                            if (elementChartJson?.["sleep" + i] > highestNightSleep) {
                                                highestNightSleep = elementChartJson?.["sleep" + i];
                                                highestNightSleepIndex = i;
                                            }
                                        }
                                        if (elementChartJson?.["interrupt" + i]) {
                                            interruptNightSumm += parseFloat(elementChartJson?.["interrupt" + i]);
                                        }
                                    }
                                    if (interruptNightSumm > 0) {
                                        elementChartJson['sleep' + highestNightSleepIndex] -= interruptNightSumm?.toFixed(2);
                                    }
                                }
                            }
                        }
                    }
                    const sleepPredictionQuery = new SleepPredictionQueryDto();
                    for (var key in query) {
                        if (query.hasOwnProperty(key)) {
                            sleepPredictionQuery[key] = query?.[key];
                            if (key === 'toDate') {
                                sleepPredictionQuery[key] = (moment(sleepToDate)?.utc()?.add(4, 'days'))?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                            }
                        }
                    }
                    sleepPredictionQuery.period = 'daily';
                    aerialClient?.getSleepPrediction(sleepPredictionQuery)?.then(
                        res => {
                            res?.predictions?.filter(checkSleepPrediction).forEach((element, index) => {
                                chartJson.forEach(chartElement => {
                                    if (moment(chartElement?.timestamp).utc()?.format('yyyy-MM-DD') === (moment(element?.timestamp)?.utc()?.format('yyyy-MM-DD'))) {
                                        chartElement.predictionDuration = element?.sleepDuration?.value / 60;
                                        chartElement.predictionRangeLow = element?.sleepDuration?.rangeLow / 60;
                                        chartElement.predictionRangeHigh = element?.sleepDuration?.rangeHigh / 60;
                                        chartElement.predictionTopLine = ((element?.sleepDuration?.rangeHigh - element?.sleepDuration?.rangeLow) / 60);
                                    }
                                });
                            });
                            setSleepDataChart?.([...chartJson]);
                        }
                    )
                }
            }
        )
    }

    const sleepRequestResetPage = () => {
        sleepRequest();
        setPageSleep(0);
    }

    const RenderBars = (sleepTimeHours, sleepPredictionLow, sleepPredictionHigh) => {
        if (sleepPredictionLow <= sleepTimeHours && sleepTimeHours <= sleepPredictionHigh) {
            return <Cell fill="#00A896" />;
        } else {
            return <Cell fill="#F44343" />;
        }
    }

    const renderTickSleep = (value) => {
        if (value === 10) {
            return '10h+'
        } else {
            return value;
        }
    };

    const handleSleepTabChange = (event, value) => {
        setSleepActiveTab(value);
        if (value === 1) {
            setShowSleepTable(false);
        } else {
            setShowSleepTable(true);
        }
    };

    const renderTickActivity = (value) => {
        if(activityPeriodSelectionAux === 'hourly') {
                return value + 'mins';
        } else {
            if (value === 6) {
                return '6h+'
            } else {
                return value + "h";
            }
        }
    };

    const handleActivityPeriodSelection = (e) => {
        setActivityPeriodSelection(e?.target?.value);
    }

    const handleActivityChangePage = (event, newPage) => {
        setPageActivity(newPage);
    };

    const handleActivityChangeRowsPerPage = (event) => {
        setRowsPerPageActivity(parseInt(event?.target?.value));
        setPageActivity(0);
    };

    const changeActivityFromDate = (e) => {
        setActivityFromDate(e?.target?.value);
    }

    const changeActivityToDate = (e) => {
        setActivityToDate(e?.target?.value);
    }

    const activityRequest = (e) => {
        if (!record?.clientExternalPartners || !record?.clientExternalPartners?.aerialGroupId) {
            return;
        }
        let periodAdded = "";
        let periodAddedQuantity = 0;
        let periodFormatter = "";
        //condiciones para construir el startdate y el end date según el periodo seleccionado por el usuario
        const query = new ActivitySummaryQueryDto();
        if (activityPeriodSelection === "monthly") {
            query.fromDate = monthCalendarValue[0]?.clone()?.startOf('month')?.format('yyyy-MM-DDT00:00:00+00:00');
            query.toDate = monthCalendarValue[1]?.clone()?.format('yyyy-MM-DDT00:00:00+00:00');
            periodAdded = "months";
            periodFormatter = "yyyy-MM";
            periodAddedQuantity = 0;
        } else if (activityPeriodSelection === "hourly") {
            isDST= moment(query.toDate).tz('Europe/Madrid').isDST();
            query.fromDate = isDST? moment(activityFromDate)?.add(-1, 'days').format('yyyy-MM-DDT22:00:00+00:00'):moment(activityFromDate)?.add(-1, 'days').format('yyyy-MM-DDT23:00:00+00:00');
            query.toDate = isDST? moment(activityFromDate)?.format('yyyy-MM-DDT21:59:59+00:00'):moment(activityFromDate)?.format('yyyy-MM-DDT22:59:59+00:00');
            periodAdded = "hours";
            periodFormatter = "yyyy-MM-DDTHH";
            periodAddedQuantity = 0;
        } else {
            periodFormatter = "yyyy-MM-DD";
            if (activityPeriodSelection === "weekly") {
                query.fromDate = moment(activityFromDate)?.startOf('week')?.add(-1, 'days')?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                query.toDate = moment(activityToDate)?.endOf('week')?.add(-1, 'days')?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                periodAdded = "weeks";
            } else if (activityPeriodSelection === "daily") {
                query.fromDate = moment(activityFromDate)?.add(-1,'days').format('yyyy-MM-DDTHH:mm:ss+00:00');
                query.toDate = moment(activityToDate)?.utc()?.format('yyyy-MM-DDTHH:mm:ss+00:00');
                periodAdded = "days";
                periodAddedQuantity = 0;
            }
        }

        if ((moment(query?.toDate))?.isBefore(moment(query?.fromDate))) {
            notify('La fecha de fin no puede ser menor que la fecha de inicio', 'error')
            return;
        }

        query.groupId = record?.clientExternalPartners.aerialGroupId;
        query.period = activityPeriodSelection;
        const activityPredictionQuery = new ActivityPredictionQueryDto();
        for (var key in query) {
            if (query?.hasOwnProperty(key)) {
                activityPredictionQuery[key] = query?.[key];
            }
        }
        if (activityPeriodSelection === 'daily') {
            activityPredictionQuery.toDate = moment(activityPredictionQuery?.toDate)?.utc()?.add(3, 'days')?.format('yyyy-MM-DDTHH:mm:ss+00:00');
        }
        let elementJson = {};
        let listJson = [];
        aerialClient?.getActivitySummary(query).then(
            res => {
                if (res.summaries) {
                    for (var m = (moment(query?.fromDate)?.utc()?.add(periodAddedQuantity, periodAdded)); m?.isSameOrBefore(moment(query?.toDate)?.add(periodAddedQuantity, periodAdded)); m?.add(1, periodAdded)) {
                            elementJson = {};
                            elementJson.activityTime = 0;
                            elementJson.activityPercentage = 0;
                            elementJson.activityNewPercentage = 0;
                            elementJson.activityDate = getActivityDate(m?.utc()?.format('yyyy-MM-DDTHH:mm:ss+00:00'), false);
                            elementJson.activityChartDate = getActivityDate(m?.utc()?.format('yyyy-MM-DDTHH:mm:ss+00:00'), true);
                            elementJson.timestamp = m?.utc()?.format('yyyy-MM-DDTHH:mm:ss+00:00');

                            elementJson.predictionRangeLow = 0;
                            elementJson.predictionRangeHigh = 0;
                            elementJson.predictionTopLine = 0;
                            elementJson.predictionValue = 0;
                            // eslint-disable-next-line
                            res?.summaries?.filter(checkActivity)?.forEach(element => {
                                if ((m?.utc()?.format(periodFormatter) === moment(element?.timestamp)?.utc()?.format(periodFormatter))) {
                                    let currentMinutes = parseInt(element?.nbMinutes?.value);
                                    elementJson.activityTime = getActivityTime(currentMinutes);
                                    elementJson.activityPercentage = getActivityTimeDecimal(currentMinutes, element?.timestamp);
                                    elementJson.activityNewPercentage = getActivityPercentage(currentMinutes, element.timestamp);
                                    elementJson.activityDate = getActivityDate(m?.format('yyyy-MM-DDTHH:mm:ss+00:00'), false);
                                    element.activityChartDate = getActivityDate(m?.format('yyyy-MM-DDTHH:mm:ss+00:00'), true);
                                }
                            });
                            listJson.push(elementJson);
                    }
                    aerialClient.getActivityPrediction(activityPredictionQuery).then(
                        res => {
                            res?.predictions?.filter(checkActivityPrediction).forEach((predictionElement, index) => {
                                listJson.forEach((listJsonItem, jsonIndex) => {
                                    if (moment(predictionElement?.timestamp).utc().format(periodFormatter) === moment(listJsonItem?.timestamp)?.utc()?.format(periodFormatter)) {
                                        listJsonItem.predictionRangeLow = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.rangeLow), predictionElement?.timestamp);
                                        listJsonItem.predictionRangeHigh = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.rangeHigh), predictionElement?.timestamp);
                                        listJsonItem.predictionTopLine = listJsonItem?.predictionRangeHigh - listJsonItem?.predictionRangeLow;
                                        listJsonItem.predictionValue = getActivityTimeDecimal(parseInt(predictionElement?.nbMinutes?.value), predictionElement?.timestamp);
                                    };

                                })
                            })
                            setActivityData([...listJson]);
                        }
                    )
                }
            }
        )


    }

    const activityRequestResetPage = () => {
        setActivityPeriodSelectionAux(activityPeriodSelection);
        activityRequest();
        setPageActivity(0);
    }

    const getActivityPercentage = (minutes, timestamp) => {
        if (activityPeriodSelection === "hourly") {
            return parseInt((minutes / 60) * 100);
        } else if (activityPeriodSelection === "daily") {
            return parseInt((minutes / 840) * 100);
        } else if (activityPeriodSelection === "weekly") {
            return parseInt((minutes / 840) * 100);
        } else if (activityPeriodSelection === "monthly") {
            return (parseInt((minutes / 840) * 100));
        } else {
            return 0;
        }
    };

    const getActivityTimeDecimal = (minutes, timestamp) => {
        if (activityPeriodSelection !== "hourly") {
            // Dividir los minutos por 60 para obtener las horas
            let hours = minutes / 60;
            // Redondear las horas a dos decimales
            return Math.round(hours * 100) / 100;
        } else {
            return minutes;
        }
    };

    const getActivityTime = (minutes) => {
        if (activityPeriodSelection === "hourly") {
            return minutes;
        } else {
            var hours = Math?.floor(minutes / 60);
            var mins = minutes - hours * 60;
            if (mins < 10) { mins = "0" + mins }
            return hours + ":" + mins;
        }
    };

    const getActivityDate = (timestamp, showInChart) => {
        if (activityPeriodSelection === 'hourly') {
            return moment(timestamp)?.utc()?.add(isDST?2:1,'hours').format("HH:mm");
        } else if (activityPeriodSelection === "monthly") {
            return moment(timestamp)?.tz("Europe/Madrid").startOf('month')?.format("MMMM").toUpperCase();
        } else if (activityPeriodSelection === 'weekly') {
            if (showInChart) {
                return moment(timestamp)?.add(2, 'days')?.format("DD/MM");
            } else {
                return moment(timestamp)?.utc()?.add(2, 'days')?.format("DD/MM/YYYY");
            }
        } else {
            if (showInChart) {
                return moment(timestamp)?.utc().add(1,'days')?.format("DD/MM");
            } else {
                return moment(timestamp)?.utc()?.add(1,'days').format("DD/MM/YYYY");
            }

        }
    };

    const checkActivity = (row) => {
        return row?.nbMinutes?.value != null;
    }

    const checkActivityPrediction = (row) => {
        return row?.nbMinutes?.rangeLow != null && row?.nbMinutes?.rangeHigh != null;
    }

    const checkSleepPrediction = (row) => {
        return row?.sleepDuration?.rangeLow != null && row?.sleepDuration?.rangeHigh != null;
    }

    const handleActivityTabChange = (event, value) => {
        setActivityActiveTab(value);
        if (value === 1) {
            setShowActivityTable(false);
        } else {
            setShowActivityTable(true);
        }
    };

    const RenderActivityBars = (activityPercentage, activityPercentageRangeLow, activityPercentageRangeHigh) => {
        if (activityPercentageRangeLow <= activityPercentage && activityPercentage <= activityPercentageRangeHigh) {
            return <Cell fill="#66BC31" />;
        } else {
            return <Cell fill="#F31F1F" />;
        }

    }

    const renderActivityListPeriodHeader = () => {
        if (activityPeriodSelection === "hourly") {
            return "Periodo (Horas)";
        } else if (activityPeriodSelection === "daily") {
            return "Periodo (Días)";
        } else if (activityPeriodSelection === "weekly") {
            return "Periodo (Semanas)";
        } else {
            return "Periodo (Meses)";
        }
    };
    const renderActivityListTimeHeader = () => {
        if (activityPeriodSelection === "hourly") {
            return "Tiempo movimiento (mins)";
        } else {
            return "Tiempo movimiento (horas)";
        }
    };

    useEffect(() => {

        sleepRequest();
        activityRequest();
        // motionRequest();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {

        if (!(record.clientExternalPartners && record.clientExternalPartners.aerialGroupId)) {
            var tabSpans = document?.querySelectorAll('.MuiTab-root > .MuiTab-wrapper');
            tabSpans?.forEach(tabSpan => {
                if (tabSpan?.innerText === 'AERIAL') {
                    tabSpan.parentElement.style.display = 'none';
                }
            });
        }

        var tabs = document?.querySelectorAll('.MuiTabs-root > .MuiTabs-scroller > .MuiTabs-flexContainer > .MuiTab-root');
        var saveDeleteFooter = document?.querySelectorAll('.MuiToolbar-root.MuiToolbar-regular.toolbar.MuiToolbar-gutters');
        tabs.forEach(tab => {
            let tabText = tab?.childNodes[0]?.innerText;
            if (tabText === 'AERIAL') {
                tab.onclick = function () {
                    saveDeleteFooter[0].style.display = 'none';
                }
            } else if (tabText !== "VER LISTADO" && tabText !== "VER GRÁFICA") {
                tab.onclick = function () {
                    saveDeleteFooter[0].style.display = 'flex';
                }
            }
        });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!(record?.clientExternalPartners && record?.clientExternalPartners?.aerialGroupId)) {
        return (<></>)
    } else {
        return (
            <>
                <FormControl className={classes?.formControl} style={{ width: "20rem" }}>
                    <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={aerialSelection}
                        onChange={handleAerialSelection}
                    >
                        <MenuItem value={1}>Calidad del descanso</MenuItem>
                        <MenuItem value={2}>Actividad</MenuItem>
                    </Select>
                </FormControl>
                {aerialSelection === 1 &&
                    <>
                        <div display="flex" style={{ marginTop: "2rem" }}>
                            <FormControl style={{ width: "20rem" }} className={classes?.formControl} size="small">
                                <MUITextField
                                    id="date"
                                    label="Fecha inicio"
                                    type="date"
                                    value={sleepFromDate}
                                    size="small"
                                    onChange={changeSleepFromDate}
                                    fullWidth="false"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <FormControl style={{ width: "20rem", marginLeft: "2rem" }} size="small">
                                <MUITextField
                                    id="date2"
                                    label="Fecha fin"
                                    type="date"
                                    size="small"
                                    value={sleepToDate}
                                    onChange={changeSleepToDate}
                                    fullWidth="false"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </FormControl>
                            <MUIButton color="primary" onClick={(e) => sleepRequestResetPage()} style={{ marginTop: "1rem", marginLeft: "1rem" }}>Enviar</MUIButton>
                        </div>
                        <Tabs value={sleepActiveTab} onChange={handleSleepTabChange} aria-label="basic tabs example">
                            <Tab icon={<FormatListBulletedIcon />} iconPosition="start" label="Ver Listado" value={0} />
                            <Tab icon={<EqualizerIcon />} iconPosition="start" label="Ver Gráfica" value={1} />
                        </Tabs>
                    </>
                }
                {aerialSelection === 1 &&
                    <>
                        {!showSleepTable && <ResponsiveContainer width="65%" aspect={2.5} >
                            <ComposedChart style={{ marginTop: "1rem" }} data={sleepDataChart}>
                                <Tooltip content={<CustomTooltip />} />
                                <Area type="monotone" dataKey="predictionRangeLow" fill="transparent" stackId="sl" />
                                <Area type="monotone" dataKey="predictionTopLine" fill="#4278f5" stroke="#4278f5" stackId="sl" />
                                <Bar dataKey="sleep1" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt1" stackId="b" barSize={15} fill="#000000" />
                                <Bar dataKey="sleep2" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt2" stackId="b" barSize={15} fill="#000000" />
                                <Bar dataKey="sleep3" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt3" stackId="b" barSize={15} fill="#000000" />
                                <Bar dataKey="sleep4" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt4" stackId="b" barSize={15} fill="#000000" />
                                <Bar dataKey="sleep5" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt5" stackId="b" barSize={15} fill="#FF0000" />
                                <Bar dataKey="sleep6" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt6" stackId="b" barSize={15} fill="#FF0000" />
                                <Bar dataKey="sleep7" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt7" stackId="b" barSize={15} fill="#FF0000" />
                                <Bar dataKey="sleep8" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt8" stackId="b" barSize={15} fill="#FF0000" />
                                <Bar dataKey="sleep9" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <Bar dataKey="interrupt9" stackId="b" barSize={15} fill="#FF0000" />
                                <Bar dataKey="sleep10" stackId="b" barSize={15}>
                                    {
                                        sleepDataChart?.map((entry, index) => (
                                            <>{RenderBars(entry?.sleepDuration, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                        ))
                                    }
                                </Bar>
                                <XAxis dataKey="day" />
                                <YAxis ticks={[0, 2, 4, 6, 8, 10]} domain={[0, 10]} tickFormatter={renderTickSleep} />
                                {/* <Legend style={{ marginLeft: '4rem' }} content={renderLegend} layout="vertical" verticalAlign="middle" align="right" /> */}
                            </ComposedChart></ResponsiveContainer>}
                        {showSleepTable &&
                            <Table className="AerialTable" aria-label="customized table" style={{ whiteSpace: "nowrap", marginTop: "1rem", width: "65%" }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left">Fecha</StyledTableCell>
                                        <StyledTableCell align="left">Inicio descanso</StyledTableCell>
                                        <StyledTableCell align="left">Fin descanso</StyledTableCell>
                                        <StyledTableCell align="left">Tiempo descanso&nbsp;(horas)</StyledTableCell>
                                        <StyledTableCell align="left" style={{ whiteSpace: "normal", width: "186px" }}>Número de interrupciones</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {sleepData.slice(pageSleep * rowsPerPageSleep, pageSleep * rowsPerPageSleep + rowsPerPageSleep).map((row, index) => (
                                        <StyledTableRow key={row?.timestamp}>
                                            <StyledTableCell>
                                                {ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.format('DD/MM')?.toString() + "</b> "))}
                                            </StyledTableCell>
                                            {row?.bedTime?.value ?
                                                (moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").hour() >= 0 && moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").hour() <= 9) ?
                                                    <StyledTableCell align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp).tz("Europe/Madrid")?.format('DD/MM')?.toString() + "</b> "))}  {moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCell>
                                                    : <StyledTableCell align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp))?.format('DD/MM')?.toString() + "</b> ")}  {moment.utc(row?.bedTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCell>
                                                : <StyledTableCell align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.utc()?.format('DD/MM')?.toString() + "</b> "))} </StyledTableCell>
                                            }
                                            {row?.wakeUpTime?.value ? <StyledTableCell align="left">{ReactHtmlParser("<b>" + (moment.utc(row?.timestamp)?.tz("Europe/Madrid"))?.format('DD/MM')?.toString() + "</b> ")}  {moment.utc(row?.wakeUpTime?.value, 'HH:mm:ss+00:00')?.tz("Europe/Madrid").format('HH:mm')?.toString()}</StyledTableCell>
                                                : <StyledTableCell align="left"></StyledTableCell>}
                                            <StyledTableCell align="left">{moment().startOf('day').minutes(row?.sleepDuration?.value).format('HH:mm')}</StyledTableCell>
                                            <StyledTableCell align="left"> {parseInt(row?.nbInterruptions?.value)}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={sleepData?.length}
                                            rowsPerPage={rowsPerPageSleep}
                                            page={pageSleep}
                                            onChangePage={handleChangePage}
                                            onChangeRowsPerPage={handleChangeRowsPerPage}
                                            rowsPerPageOptions={[7, 15, 30]}
                                            labelRowsPerPage={<span>Filas por página:</span>}
                                            labelDisplayedRows={({ from = pageSleep * rowsPerPageSleep, to = rowsPerPageSleep + rowsPerPageSleep, count = sleepData?.length() }) => `Registros totales: ${count}      ---          Del ${from} al ${to} `}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>}
                    </>
                }
                {
                    aerialSelection === 2 &&
                    <>
                        <FormControl className={classes?.formControl} style={{ width: "20rem", marginLeft: "2rem" }}>
                            <InputLabel id="demo-simple-select-label">Periodo</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="select-activity-period"
                                value={activityPeriodSelection}
                                onChange={handleActivityPeriodSelection}
                            >
                                <MenuItem value="hourly">Horas</MenuItem>
                                <MenuItem value="daily">Días</MenuItem>
                                <MenuItem value="weekly">Semanas</MenuItem>
                                <MenuItem value="monthly">Meses</MenuItem>
                            </Select>
                        </FormControl>
                        <div display="flex" style={{ marginTop: "2rem" }}>
                            {activityPeriodSelection === "hourly" &&
                                <FormControl style={{ width: "20rem" }} className={classes?.formControl} size="small">
                                    <MUITextField
                                        id="date3"
                                        label="Día"
                                        type="date"
                                        value={activityFromDate}
                                        size="small"
                                        onChange={changeActivityFromDate}
                                        fullWidth="false"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormControl>
                            }
                            {activityPeriodSelection === "daily" &&
                                <>
                                    <FormControl style={{ width: "20rem" }} className={classes?.formControl} size="small">
                                        <MUITextField
                                            id="date4"
                                            label="Fecha inicio"
                                            type="date"
                                            value={activityFromDate}
                                            size="small"
                                            onChange={changeActivityFromDate}
                                            fullWidth="false"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: "20rem", marginLeft: "2rem" }} size="small">
                                        <MUITextField
                                            id="date5"
                                            label="Fecha fin"
                                            type="date"
                                            size="small"
                                            value={activityToDate}
                                            onChange={changeActivityToDate}
                                            fullWidth="false"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </>
                            }
                            {activityPeriodSelection === "weekly" &&
                                <>
                                    <FormControl style={{ width: "20rem" }} className={classes?.formControl} size="small">
                                        <MUITextField
                                            id="date4"
                                            label="Selecciona un día de la semana inicial"
                                            type="date"
                                            value={activityFromDate}
                                            size="small"
                                            onChange={changeActivityFromDate}
                                            fullWidth="false"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                    <FormControl style={{ width: "20rem", marginLeft: "2rem" }} size="small">
                                        <MUITextField
                                            id="date5"
                                            label="Selecciona un día de la semana final"
                                            type="date"
                                            size="small"
                                            value={activityToDate}
                                            onChange={changeActivityToDate}
                                            fullWidth="false"
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </FormControl>
                                </>
                            }
                            {activityPeriodSelection === "monthly" &&
                                <>
                                    <DateRangePicker maxDate={new Date()} maxDetail="year" minDate={new Date('01/01/2021')} value={monthCalendarValue}
                                        showLeadingZeros={true} onChange={changeMonthCalendarValue} />
                                </>
                            }
                            <MUIButton variant="outlined" color="primary" onClick={(e) => activityRequestResetPage()} style={{ marginTop: "1rem", marginLeft: "9rem" }}>Enviar</MUIButton>

                        </div>
                        <Tabs value={activityActiveTab} onChange={handleActivityTabChange} aria-label="basic tabs example">
                            <Tab icon={<FormatListBulletedIcon />} iconPosition="start" label="Ver Listado" value={0} />
                            <Tab icon={<EqualizerIcon />} iconPosition="start" label="Ver Gráfica" value={1} />
                        </Tabs>
                    </>
                }
                {
                    aerialSelection === 2 &&
                    <>
                        {!showActivityTable && <ResponsiveContainer width="65%" aspect={2.5} ><ComposedChart style={{ marginTop: "1rem" }} width="60rem" height="20rem" data={activityData}>
                            <Tooltip content={<CustomActivityTooltip />} />
                            <Bar dataKey="activityPercentage" barSize={15} radius={[10, 10, 10, 10]}>
                                {
                                    activityData?.map((entry, index) => (
                                        <>{RenderActivityBars(entry?.activityPercentage, entry?.predictionRangeLow, entry?.predictionRangeHigh)}</>
                                    ))
                                }
                            </Bar>
                            <Area type="monotone" dataKey="predictionRangeLow" fill="transparent" stackId="ac" />
                            <Area type="monotone" dataKey="predictionTopLine" fill="#4278f5" stroke="#4278f5" stackId="ac" />
                            <XAxis dataKey="activityChartDate" />
                            { activityPeriodSelectionAux === 'hourly' && 
                                    <YAxis ticks={[0, 10, 20, 30, 40, 50, 60]} domain={[0, 60]} tickFormatter={renderTickActivity} />
                            } 
                            { activityPeriodSelectionAux !== 'hourly' &&
                                    <YAxis ticks={[0, 2, 4, 6]} domain={[0, 6]} tickFormatter={renderTickActivity} />
                            }
                        </ComposedChart></ResponsiveContainer>}
                        {showActivityTable &&
                            <Table className="AerialTable" aria-label="customized table" style={{ whiteSpace: "nowrap", marginTop: "1rem", width: "65%" }}>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell align="left" style={{ width: "318px" }}>{renderActivityListPeriodHeader()}</StyledTableCell>
                                        <StyledTableCell align="left" style={{ width: "318px" }}>{renderActivityListTimeHeader()}</StyledTableCell>
                                        <StyledTableCell align="left" style={{ width: "237px" }}>% movimiento</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {activityData.slice(pageActivity * rowsPerPageActivity, pageActivity * rowsPerPageActivity + rowsPerPageActivity).map((row) => (
                                        <StyledTableRow key={row?.timestamp}>
                                            <StyledTableCell align="left">{ReactHtmlParser("<b>" + row?.activityDate + "</b> ")}</StyledTableCell>
                                            <StyledTableCell align="left">{ReactHtmlParser("<b>" + row?.activityTime + "</b> ")}</StyledTableCell>
                                            <StyledTableCell align="left">{row?.activityNewPercentage}</StyledTableCell>
                                        </StyledTableRow>
                                    ))}
                                </TableBody>
                                <TableFooter>
                                    <TableRow>
                                        <TablePagination
                                            count={activityData?.length}
                                            rowsPerPage={rowsPerPageActivity}
                                            page={pageActivity}
                                            onChangePage={handleActivityChangePage}
                                            onChangeRowsPerPage={handleActivityChangeRowsPerPage}
                                            rowsPerPageOptions={[7, 15, 30]}
                                            labelRowsPerPage={<span>Filas por página:</span>}
                                            labelDisplayedRows={({ from = pageActivity * rowsPerPageActivity, to = rowsPerPageActivity + rowsPerPageActivity }) => `Del ${from} al ${to} `}
                                            backIconButtonProps={{
                                                color: "secondary"
                                            }}
                                            nextIconButtonProps={{ color: "secondary" }}
                                            SelectProps={{
                                                inputProps: {
                                                    "aria-label": "page number"
                                                }
                                            }}
                                            showFirstButton={true}
                                            showLastButton={true}
                                        />
                                    </TableRow>
                                </TableFooter>
                            </Table>}
                    </>
                }
            </>
            // }
        );
    }
};
export const usuarioEdit = props => {

    return (
        <Edit title={<UserTitle />} actions={null} mutationMode="pessimistic" {...props} transform={transformUser}>
            {UsuarioForm(props, 'edit')}
        </Edit>
    )
};

export const usuarioCreate = props => (
    <Create mutationMode="pessimistic" {...props} transform={transformUser}>
        {UsuarioForm(props)}
    </Create>
);

export const usuarioShow = props => (
    <Show title={<UserTitle />} {...props}>
        {UsuarioShowForm(props)}
    </Show>
);

const SeePasswordButton = (props) => {
    const { values } = useFormState();
    const [clientPassword, setClientPassword] = useState();

    const HandleClick = () => {
        clientClient.pass(values?.id).then((resp) => {
            setClientPassword(resp?.password)
            setTimeout(() => setClientPassword(null), 2000)
        })
    }

    return (
        <>
            {values?.viewPassword ?
                <div style={{ backgroundColor: '#f5f5f5', borderRadius: 2, width: '100%', justifyContent: 'flex-start', height: '3rem' }}>
                    <Button style={{ backgroundColor: '#d7d7d7', whiteSpace: 'nowrap', flexShrink: 0 }} label="Ver Contraseña" onClick={HandleClick}></Button>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}>
                        <p style={{ margin: 0, padding: 0 }}>{clientPassword ? clientPassword : null}</p>
                    </div>
                </div> : null
            }
        </>
    );
}

const ProfessionalInput = props => {
    const { values } = useFormState()

    const [contratos, setContratos] = useState([0])

    useEffect(() => {
        let contracts = values?.contracts?.map(c => c?.id)
        setContratos(contracts?.length ? contracts : [0])
        // eslint-disable-next-line
    }, [values?.contracts])

    return (

        <ReferenceInput key={contratos}
            label={props?.label}
            source={props?.source}
            reference={props?.reference}
            filter={props?.filter}
            filterToQuery={(value) => {
                return { nameINumber: value, contractIds: contratos }
            }}
        >
            <AutocompleteInput translateChoice={false} optionText={(record) => {
                if (!record) return null
                return record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames
            }} resettable={true} disabled={props.identity.roles === "ALL_MANAGER" ||
                props?.identity?.roles === "USER_HISTORY_MANAGER" ||
                props?.identity?.roles === "DYNAMICS_MANAGER" || props?.identity?.roles === "LOGISTICS_TECHNICIAN"}
            />
        </ReferenceInput>
    )
}

const ReadOnlyMultiSelect = props => {
    const record = useRecordContext();
    const [values,setValues] = useState([]);

    useEffect(() => {
        let localValues = [];
        record[props?.property].forEach((property) => {
          const likeValue = props?.valuesList?.find((e) => e?.id === property)?.value;
          if (likeValue) {
            localValues?.push(likeValue);
          }
        });
        setValues(localValues);
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);

    return (
        <FormControl style={{backgroundColor: "#0000000a",height: "2.8rem", marginTop: "0.5rem"}}>
             <InputLabel id="multi-select-chips-label" style={{fontFamily: "FS Matthew", paddingTop: "0.4rem", paddingLeft: "0.6rem"}}>{props.label}</InputLabel>
            <Select
            labelId="multi-select-chips-label"
            id="multi-select-chips"
            multiple
            disabled
            value={values}
            renderValue={(selected) => (
                <div>
                {selected?.map((value) => (
                  <Chip key={value} label={value} style={{ margin: '0.2rem' }} />
                ))}
              </div>
            )}
            >
                {values?.map((item) => (
                    <MenuItem key={item} value={item}>
                    {item.label}
                    </MenuItem>
                ))}
            </Select>
         </FormControl>
    )
    
}

export const UsuarioForm = (props, mode = 'create') => {
    const [provinceList, setprovinceList] = useState()
    const [countriesList, setcountriesList] = useState()
    const [languajeList, setlanguajeList] = useState()
    const [likeList, setlikeList] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [gender, setGender] = useState()
    const [dependencyDegree, setDependencyDegree] = useState()
    const [therapeuticProfile, setTherapeuticProfile] = useState()
    const [technologicalIndependenceList, setTechnologicalIndependenceList] = useState()
    const [usesTechHelpList, setUsesTechHelpList] = useState()
    const classes = TabsUseStyles();
    const chipClasses = ChipArrayUseStyles();
    const redirect = useRedirect();
    const notify = useNotify();
    const { identity, loading } = useGetIdentity();
    const [useAerial, setUseAerial] = useState();
    const [civilStatus, setCivilStatus] = useState();
    const [profession, setProfession] = useState();
    const [postalCode, setPostalCode] = useState('');
    const [postalCodeValid, setPostalCodeValid] = useState(true);

    const { permissions } = usePermissions();

    const [mutate] = useMutation();
    const save = useCallback(
        async (values) => {
            try {
                const res = await mutate({
                    type: mode === 'create' ? 'create' : 'update',
                    resource: 'users',
                    payload: { data: transformUser(values) },
                }, { returnPromise: true });
                notify(`ra.notification.${mode === 'create' ? 'created' : 'updated_item'}`)
                if (mode === 'create') {
                    redirect('edit', props?.basePath, res?.data?.id, res?.data)
                } else {
                    redirect('list', props?.basePath)
                }
            } catch (error) {
                if (error?.error && error?.error?.code === 4001) {
                    notify(error?.error?.message, 'error')
                    return
                }
                if (error?.error && error?.error?.code === 4002) {
                    notify(error?.error?.message, 'error')
                    return {
                        password: error?.error?.message
                    };
                }
                if (error?.error && error?.error?.code === 4003) {
                    notify(error?.error?.message, 'error')
                    return {
                        identificationNumber: error?.error?.message
                    };
                }
                if (error?.error && error?.error?.code === 4004) {
                    notify(error?.error?.message, 'error')
                    return {
                        mainContractId: error?.error?.message
                    };
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [mutate, mode, notify, props?.basePath, redirect],
    );

    const handleRejected = (rejectedFile) => {
        notify('Seleccione un tipo de archivo válido (de tipo imagen y con un tamaño máximo de 5 megas). Si guarda ahora la fotografía anterior será borrada', 'error', { autoHideDuration: 12000 });
    };

    useEffect(() => {
        console.log('Loading user form -> Provinces');
        loginClient.renew().then(res => {
            localStorage.setItem('token', res.accessToken)
        })
         
        getCountries()?.then(
            res => {
                setcountriesList(res?.elements);
            }
        )
        getLanguajes()?.then(
            res => {
                setlanguajeList(res?.elements);
            }
        )
        getLikes()?.then(
            res => {
                setlikeList(res?.elements);
            }
        )
        // getClientType().then(
        //     res => {
        //         setclientTypes(res.elements);
        //     }
        // )
        getWorkingCenter()?.then(
            res => {
                setworkingCenter(res?.elements);
            }
        )
        getGender()?.then(
            res => {
                setGender(res?.elements);
            }
        )
        getDependencyDegree()?.then(
            res => {
                setDependencyDegree(res?.elements);
            }
        )
        getTherapeuticProfile()?.then(
            res => {
                setTherapeuticProfile(res?.elements);
            }
        )
        getTechnologicalIndependence()?.then(
            res => {
            setTechnologicalIndependenceList(res?.elements);
            }
        )
        getUsesTechHelp()?.then(
            res => {
            setUsesTechHelpList(res?.elements);
            }
        )
        getCivilStatus()?.then(
            res => {
                setCivilStatus(res?.elements);
            }
        )
        getProfession()?.then(
            res => {
                setProfession(res?.elements);
            }
        )
        
    }, []);


    const handleChange = (event) => {
        const value = event.target.value;
        
        // Verifica que el valor solo contenga números
        const isNumeric = /^[0-9]*$/.test(value);

        if (isNumeric && (value.length >= 5 || value.length === 0)) {
            setPostalCode(value);
        }

    };

    const parsePostalCode = (value) => {
        if (!value) return value;
        return value.replace(/[^\d]/g, '').slice(0, 5);
    };

    const handleSetProvinceList = (data) => {
        if (data) {
            setprovinceList(data);
        }
    }

    const handleSetPostalCodeValid = (data) => {
        setPostalCodeValid(data);   
    }

    const formatPostalCode = (value) => {
        // Si el valor es nulo o indefinido, retornamos el valor tal cual
        if (!value) return value;
    
        // Eliminamos todos los caracteres que no sean números
        const onlyNumbers = value.replace(/[^\d]/g, '');
    
        // Limitamos la longitud a 5 caracteres
        const maxLength = 5;
        const formattedValue = onlyNumbers.slice(0, maxLength);
    
        return formattedValue;
    };

    if (loading && !identity) {
        return <div></div>;
    }

    return (
        <TabbedForm
            style={{ minHeight: "31.5rem" }}
            tabs={<TabbedFormTabs variant="scrollable" scrollButtons="auto" {...props} />}
            toolbar={<CustomToolbarPermissions perm="users.delete" />}
            save={save}
            classes={classes}
            className="userTabs"
            validate={mode === 'create' ? validateUserCreation : validateUserEdit}
        >
            {/* Crear */}
            {
                mode === 'create' ?
                    <FormTab label="Contratos" contentClassName="">
                        <ArrayInput label="Contratos" source="contracts" id="arrayCustom" validate={[required()]}>
                            <SimpleFormIterator disableRemove={identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} disableAdd={identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} >
                                <ReferenceInput perPage={1000} label="Contrato" source="id" reference="contracts" validate={[required()]}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}>
                                    <AutocompleteInput
                                        optionText="contractNumber"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({
                                        scopedFormData
                                    }) =>
                                    (
                                        scopedFormData?.id ? 
                                        <div>
                                            <BooleanInputCustom contractId={scopedFormData.id} source="isMain" {...props} isDisabled={false} /> 
                                            <TextInput style={{ display: 'none' }} source="mainContractId" />
                                        </div> : null
                                    )
                                    }
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <TextInput style={{ display: 'none' }} source="contractsValidation" />
                    </FormTab>
                    :
                    <FormTab label="Usuario" contentClassName="tab-container">

                        <ImageInput source="picture" label="Foto del usuario" accept="image/*" multiple={false} maxSize={5242880} options={{ onDropRejected: handleRejected }}>
                            <ImageField source="src" title="title" />
                        </ImageInput>

                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                            <TextInput label="Nombre" source="name" validate={[maxLength(50, 'Debe tener como máximo 50 caracteres.')]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} />
                            <TextInput label="Apellidos" source="surnames" validate={[maxLength(75, 'Debe tener como máximo 75 caracteres.')]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} />
                            <TextInput label="NIF/NIE" source="identificationNumber" disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} />
                            <TextInput label="Alias" source="alias"  validate={[maxLength(50, 'Debe tener como máximo 50 caracteres.')]} disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                        </div>
                        <TextInput label="Email" source="email" validate={[email()]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} />

                        {
                            mode === 'create' ? <TextInput label="Contraseña" source="password" /> : null
                        }
                        {
                            languajeList ?
                            (identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN")?
                            <ReadOnlyMultiSelect valuesList={languajeList} label="Idioma" property="languages"/>:
                                <AutocompleteArrayInput
                                    label="Idioma"
                                    source="languages"
                                    choices={languajeList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    classes={chipClasses}
                                />
                                : null
                        }
                        {
                            likeList ?
                            (identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN")?
                            <ReadOnlyMultiSelect valuesList={likeList} label="Gustos/Preferencias" property="clientLikes"/>:
                                <AutocompleteArrayInput
                                    label="Gustos/Preferencias"
                                    source="clientLikes"
                                    choices={likeList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    classes={chipClasses}
                                />
                                : null
                        }
                        <ProfessionalInput label="Psicólogo de referencia"
                            source="supportProfessional.id"
                            reference="professional"
                            identity={identity} mode={mode}>
                        </ProfessionalInput>
                        <ProfessionalInput label="Trabajador Social de referencia"
                            source="referenceSocialWorker.id"
                            reference="professional"
                            filter={{ type: ['SOCIAL_WORKER'] }}
                            identity={identity} mode={mode}>
                        </ProfessionalInput>
                        <div className="new-line">
                            <ReferenceInput
                                label="Dispositivo android"
                                source="androidDevice.id"
                                reference="devices"
                                filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                            >
                                <AutocompleteInput translateChoice={false} optionText={(record) => {
                                    return record.code
                                }} resettable={true} /*disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}*/ />
                            </ReferenceInput>
                            <ReferenceInput
                                label="Dispositivo comunicación"
                                source="communicationDevice.id"
                                reference="comunicationDevices"
                                filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                            >
                                <AutocompleteInput translateChoice={false} optionText={(record) => {
                                    return record.code
                                }} resettable={true} /*disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}*/ />
                            </ReferenceInput>
                        </div>
                        {
                            workingCenter ?
                                <AutocompleteInput
                                    label="Centro de Trabajo"
                                    source="associatedWorkingCenterId"
                                    choices={workingCenter}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        <div className="new-line">
                            <TextInput label="Versión app Vera" source="veraAppVersion" disabled={true} />
                            <DateTimeInput label="Fecha versión app Vera" source="veraAppVersionDate" disabled={true} />
                        </div>
                        <div className="new-line">
                            <DateInput label="Fecha de Alta" source="activeDate" disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                            <DateInput label="Fecha de Baja" source="leavingDate" disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                            <DateInput label="Fecha de Inactividad" source="inactivityDate" disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                        </div>
                        <DateInput label="Fecha Nacimiento" source="birthDate" disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} />
                        <DateInput label="Fecha de Instalación" source="setupDate" disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                        {
                            gender ?
                                <AutocompleteInput
                                    label="Sexo"
                                    source="genderId"
                                    choices={gender}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        {
                            civilStatus ?
                                <AutocompleteInput
                                    label="Estado Civil"
                                    source="civilStatusId"
                                    choices={civilStatus}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        {
                            profession ?
                                <AutocompleteInput
                                    label="Profesión"
                                    source="professionId"
                                    choices={profession}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        {
                            dependencyDegree ?
                                <AutocompleteInput
                                    label="Grado Dependencia"
                                    source="dependencyDegreeId"
                                    choices={dependencyDegree}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        {   
                            therapeuticProfile ?
                            (identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN")?
                            <ReadOnlyMultiSelect valuesList={therapeuticProfile} label="Perfil Terapéutico" property="therapeuticalProfiles"/>:
                                <AutocompleteArrayInput
                                    label="Perfil Terapéutico"
                                    source="therapeuticalProfiles"
                                    choices={therapeuticProfile}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                    classes={chipClasses}
                                />
                                :null
                        }
                        {
                            technologicalIndependenceList ?
                                <AutocompleteInput
                                    label="Autonomía a la conexión de la persona"
                                    source="technologicalIndependenceId"
                                    choices={technologicalIndependenceList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                />
                                : null
                        }
                        {
                            usesTechHelpList?
                                <div className="new-line">
                                    <AutocompleteInput
                                        label="¿Utiliza ayudas técnicas?"
                                        source="usesTechHelpId"
                                        choices={usesTechHelpList}
                                        optionText="value"
                                        optionValue="id"
                                        options={{ InputProps: { autoComplete: 'off' } }}
                                        resettable={true}
                                        disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN" || identity?.roles === "LOGISTICS_TECHNICIAN"}
                                    />
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (formData?.usesTechHelpId && formData?.usesTechHelpId !== usesTechHelpList.filter(x => x.value === 'No')[0]?.id) &&
                                            <TextInput style={{width: '72%'}} label="¿Cuáles?" source="whichOnesTechHelp" 
                                            disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN" || identity?.roles === "LOGISTICS_TECHNICIAN"} />
                                        }
                                    </FormDataConsumer>
                                </div>
                                : null                      
                        }
                        <div className="new-line">
                        <UseAerialChildSwitchAndSelect  setUseAerial={setUseAerial} useAerial={useAerial} identity={identity}  isDisabled={false} />
                        {!useAerial && <span style={{width: '80%'}}></span>}
                        </div>
                        {
                            identity.roles === "SUPER_ADMIN" ?
                                <SeePasswordButton></SeePasswordButton>
                                : null
                        }
                    </FormTab>
            }

            {
                mode === 'create' ?
                    <FormTab label="Usuario" contentClassName="tab-container">
                        {
                            <ImageInput source="picture" label="Foto del usuario" accept="image/*" multiple={false} maxSize={5242880} removeIcon={false}
                                options={{
                                    onDropRejected: handleRejected
                            }}>
                                <ImageField source="src" title="title" />
                            </ImageInput>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'center' }}>
                            <TextInput label="Nombre" source="name" validate={[maxLength(50, 'Debe tener como máximo 50 caracteres.')]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                            <TextInput label="Apellidos" source="surnames" validate={[maxLength(75, 'Debe tener como máximo 75 caracteres.')]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                            <TextInput label="NIF/NIE" source="identificationNumber" disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                            <TextInput label="Alias" source="alias" validate={[maxLength(50, 'Debe tener como máximo 50 caracteres.')]} disabled={identity?.roles !== "ADMIN" && identity?.roles !== "SUPER_ADMIN"} />
                        </div>
                        <TextInput label="Email" source="email" validate={[email()]} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />

                        {
                            mode === 'create' ? <TextInput label="Contraseña" source="password" /> : null
                        }
                        {
                            languajeList ?
                                <AutocompleteArrayInput
                                    label="Idioma"
                                    source="languages"
                                    choices={languajeList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    classes={chipClasses}
                                />
                                : null
                        }
                        {
                            likeList ?
                                <AutocompleteArrayInput
                                    label="Gustos/Preferencias"
                                    source="clientLikes"
                                    choices={likeList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    classes={chipClasses}
                                />
                                : null
                        }
                        <ProfessionalInput label="Psicólogo de referencia"
                            source="supportProfessional.id"
                            reference="professional"
                            identity={identity} mode={mode}>
                        </ProfessionalInput>
                        <ProfessionalInput label="Trabajador Social de referencia"
                            source="referenceSocialWorker.id"
                            reference="professional"
                            filter={{ type: ['SOCIAL_WORKER'] }}
                            identity={identity} mode={mode}>
                        </ProfessionalInput>
                        <div className="new-line">
                            <ReferenceInput
                                label="Dispositivo android"
                                source="androidDevice.id"
                                reference="devices"
                                filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                            >
                                <AutocompleteInput translateChoice={false} optionText={(record) => {
                                    return record.code
                                }} resettable={true} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                            </ReferenceInput>
                            <ReferenceInput
                                label="Dispositivo comunicación"
                                source="communicationDevice.id"
                                reference="comunicationDevices"
                                filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                            >
                                <AutocompleteInput translateChoice={false} optionText={(record) => {
                                    return record.code
                                }} resettable={true} disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                            </ReferenceInput>
                        </div>
                        {
                            workingCenter ?
                                <AutocompleteInput
                                    label="Centro de Trabajo"
                                    source="associatedWorkingCenterId"
                                    choices={workingCenter}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        <div className="new-line">
                            <TextInput label="Versión app Vera" source="veraAppVersion" disabled={true} />
                            <DateTimeInput label="Fecha versión app Vera" source="veraAppVersionDate" disabled={true} />
                        </div>
                        {
                            identity.roles === "ADMIN" || identity.roles === "SUPER_ADMIN"?
                                <>
                                    <DateInput label="Fecha de Alta" source="activeDate" />
                                    <DateInput label="Fecha de Baja" source="leavingDate" />
                                    <DateInput label="Fecha de Instalación" source="setupDate" />
                                </>
                            : null
                        }
                        <DateInput label="Fecha Nacimiento" source="birthDate" />
                        {
                            gender ?
                                <AutocompleteInput
                                    label="Sexo"
                                    source="genderId"
                                    choices={gender}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        {
                            civilStatus ?
                                <AutocompleteInput
                                    label="Estado Civil"
                                    source="civilStatusId"
                                    choices={civilStatus}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        {
                            profession ?
                                <AutocompleteInput
                                    label="Profesión"
                                    source="professionId"
                                    choices={profession}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        {
                            dependencyDegree ?
                                <AutocompleteInput
                                    label="Grado Dependencia"
                                    source="dependencyDegreeId"
                                    choices={dependencyDegree}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        {
                            therapeuticProfile ?
                                <AutocompleteArrayInput
                                    label="Perfil Terapéutico"
                                    source="therapeuticalProfiles"
                                    choices={therapeuticProfile}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                    classes={chipClasses}
                                    
                                />
                                : null
                        }
                        {
                            technologicalIndependenceList ?
                                <AutocompleteInput
                                    label="Autonomía a la conexión de la persona"
                                    source="technologicalIndependenceId"
                                    choices={technologicalIndependenceList}
                                    optionText="value"
                                    optionValue="id"
                                    options={{ InputProps: { autoComplete: 'off' } }}
                                    resettable={true}
                                    disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                />
                                : null
                        }
                        {
                            usesTechHelpList?
                                <div className="new-line">
                                    <AutocompleteInput
                                        label="¿Utiliza ayudas técnicas?"
                                        source="usesTechHelpId"
                                        choices={usesTechHelpList}
                                        optionText="value"
                                        optionValue="id"
                                        options={{ InputProps: { autoComplete: 'off' } }}
                                        resettable={true}
                                        disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"}
                                    />
                                    <FormDataConsumer>
                                        {({ formData, ...rest }) => (formData?.usesTechHelpId && formData?.usesTechHelpId !== usesTechHelpList.filter(x => x.value === 'No')[0]?.id) &&
                                            <TextInput style={{width: '72%'}} label="¿Cuáles?" source="whichOnesTechHelp" 
                                            disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "USER_HISTORY_MANAGER" || identity?.roles === "DYNAMICS_MANAGER"} />
                                        }
                                    </FormDataConsumer>
                                </div>
                                : null
                        }
                        {
                            identity.roles === "SUPER_ADMIN" ?
                                <SeePasswordButton></SeePasswordButton>
                                : null
                        }
                    </FormTab> : null
            }

            <FormTab label="Contacto" contentClassName="tab-container">
                <TextInput label="Teléfono Móvil" source="cellPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled={identity?.roles === "LOGISTICS_TECHNICIAN"} />
                <TextInput label="Teléfono fijo" source="homePhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>
                {
                    countriesList ?
                        <AutocompleteInput
                            label="País"
                            source="countryId"
                            choices={countriesList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={identity?.roles === "LOGISTICS_TECHNICIAN"}
                        />
                        : null
                }
                <TextInput label="Código Postal" source="postalCode" format={formatPostalCode} parse={parsePostalCode} keyboardType="numeric" onChange={handleChange} validate={[maxValue(99999, 'Debe contener 5 máximo caracteres'), validateCustomPostalCode('Código Postal Incorrecto', postalCodeValid)]} disabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>
                {
                    <AutocompleteInputTown onSetProvinceList={handleSetProvinceList} onSetPostalCodeValid={handleSetPostalCodeValid} postalCode={postalCode}{...props}  isDisabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>
                }
                {
                    <AutocompleteInputProvince provinceList={provinceList} {...props} isDisabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>         
                }
                <TextInput label="Dirección 1" source="address" disabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>
                <TextInput label="Dirección 2" source="address2" disabled={identity?.roles === "LOGISTICS_TECHNICIAN"}/>
            </FormTab>
            {
                mode !== 'create' ?
                    <FormTab label="Personas de contacto">
                        <ReferenceManyField
                            addLabel={false}
                            reference="contacts"
                            target="id"
                            sort={{ field: "created_at", order: "DESC" }}
                        >
                            <Datagrid>
                                <TextField label="Nombre" source="name" />
                                <TextField label="Apellidos" source="surnames" />
                                <TextField label="NIF/NIE" source="identificationNumber" />
                                <TextField label="Teléfono 1" source="phone1" />
                                <TextField label="Teléfono 2" source="phone2" />
                                <TextField label="Email" source="email" />
                                <ContactTypeField label="Tipo" source="typeId" />
                                {
                                    props?.permissions && props?.permissions?.contacts?.edit ?
                                        <EditContactsButton /> : null
                                }
                                {
                                    props?.permissions && props?.permissions?.contacts?.edit ?
                                        <DeleteContactsButton /> : null
                                }
                            </Datagrid>
                        </ReferenceManyField>
                        {
                            props?.permissions && props?.permissions?.contacts?.create ?
                                <AddNewContactsButton /> : null
                        }
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Historia de usuario">
                        <UserHistoryView {...props}></UserHistoryView>
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "AAPP_TECHNICIAN" && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Interacciones de usuario">
                        <UserInteractionsList {...props}></UserInteractionsList>
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "AAPP_TECHNICIAN" && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Documentos">
                        <UserDocumentsList {...props}></UserDocumentsList>
                    </FormTab>
                    : null
            }
            {/* Editar */}
            {
                mode !== 'create' && permissions?.contracts?.enabled ?
                    <FormTab label="Contratos" contentClassName="">
                        <ArrayInput label="Contratos" source="contracts" id="arrayCustom">
                            <SimpleFormIterator style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} disableRemove={identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"} disableAdd={identity?.roles === "ALL_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN" || identity?.roles === "DYNAMICS_MANAGER"} inline>
                                <ReferenceInput perPage={1000} label="Contrato" source="id" reference="contracts" disabled={identity?.roles === "ALL_MANAGER" || identity?.roles === "DYNAMICS_MANAGER" || identity?.roles === "LOGISTICS_TECHNICIAN"}>
                                    <AutocompleteInput
                                        optionText="contractNumber"
                                        validate={[required()]}
                                    />            
                                </ReferenceInput>
                                <FormDataConsumer>
                                        {({
                                            scopedFormData
                                        }) =>
                                        (
                                            scopedFormData?.id ? 
                                            <div>
                                                <BooleanInputCustom contractId={scopedFormData.id} source="isMain" {...props} isDisabled={false} identity={identity} /> 
                                                <TextInput style={{ display: 'none' }} source="mainContractId" />
                                            </div>
                                            : null
                                        )
                                        }
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <TextInput style={{ display: 'none' }} source="contractsValidation" />
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "AAPP_TECHNICIAN" ?
                    <FormTab label="Plano Vivienda">
                        <HousingPlanDocumentsList {...props}></HousingPlanDocumentsList>
                    </FormTab>
                    : null
            }
            <FormTab label="Aerial" id="aerialtab">
                <AerialTab {...props}></AerialTab>
            </FormTab>

        </TabbedForm >
    )
}
/* 
export const validateMainContractId = (value, allValues) => {
    // Verifica si el campo 'mainContractId' está vacío
    if (!allValues?.mainContractId) {
        return 'Seleccione un contrato principal';
    }
    return undefined;
};
 */
export const validateCustomPostalCode = (message, postalCodeValid) => {
    return (value) => {
        if (!postalCodeValid) {
            return message;
        }
        return undefined;
    };
};

export const AutocompleteInputTown = (props) => {
    const { postalCode , isDisabled, onSetProvinceList, onSetPostalCodeValid  } = props;
    const [townList, setTownList] = useState();
    const record = useRecordContext();

    
    useEffect(() => {

        if (record?.postalCode || postalCode.length === 5) {
            var postalCodeFilter = postalCode.length === 5 ? postalCode : record?.postalCode;

            const fetchTowns = async () => {
                try {
                    const res = await TownProvider.getTowns({ postCode: postalCodeFilter });
                    setTownList(res?.elements);
                    if(res?.elements){
                        onSetPostalCodeValid(true);
                        const resProvinces = await TownProvider.getProvinces({ townId: res?.elements[0].id});
                        record.postalCode = postalCodeFilter;
                        record.provinceId = resProvinces?.elements[0].id;
                        onSetProvinceList(resProvinces?.elements);
                    }
                    

                } catch (error) {
                    if (error?.error && error?.error?.code === 4001) {

                        onSetPostalCodeValid(false)
                        return 
                    }

                }
            };

            fetchTowns();
        }
        // eslint-disable-next-line
    }, [postalCode]);



    return (
            <AutocompleteInput
            label="Localidad"
            source="townId"
            choices={townList}
            optionText="name"
            optionValue="id"
            options={{
                InputProps: {
                    autoComplete: 'off',
                    inputProps: {

                    }
                }
            }}
            resettable={true}
            disabled={isDisabled}
        />
       
    )
}

export const AutocompleteInputProvince = (props) => {
    const { isDisabled } = props;
    
    return (
        <AutocompleteInput
        label="Provincia"
        source="provinceId"
        choices={props.provinceList}
        optionText="value"
        optionValue="id"
        options={{ InputProps: { autoComplete: 'off' } }}
        resettable={true}
        disabled={isDisabled}
        />
       
    )
}

export const UseAerialChildSwitchAndSelect = (props) => {
    const { isDisabled } = props;

    const record = useRecordContext();
    const [freeGroupIds, setFreeGroupIds] = useState();

    const handleSetUseAerial = (isChecked) => {
        props.setUseAerial(isChecked);
    };

    useEffect (() => {
        props.setUseAerial(record.clientExternalPartners?.id?true:false);
        aerialClient.unassignedGroups()?.then(
            res => {
                if(record?.clientExternalPartners?.id) {
                    res.push(record?.clientExternalPartners);
                }
                setFreeGroupIds(res);
            }
        )
        // eslint-disable-next-line
    },[]);

    return (
        <>
            <BooleanInput source="isUsingAerial" label="Usa Aerial" onChange={value => handleSetUseAerial(value)} disabled={isDisabled} />
            {props.useAerial &&
                <AutocompleteInput
                    label="Dispositivo de Presencia"
                    source="clientExternalPartners.id"
                    choices={freeGroupIds}
                    optionText="groupIdAndResidentName"
                    optionValue="id"
                    options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                    resettable={true}
                    disabled={isDisabled}
                    validate={(value) => props.useAerial && !value ? 'Este campo es requerido' : undefined}
                />
            }
        </>
        );
};

export const UsuarioShowForm = (props, mode = "show") => {
    const [provinceList, setprovinceList] = useState()
    const [countriesList, setcountriesList] = useState()
    const [languajeList, setlanguajeList] = useState()
    const [likeList, setlikeList] = useState()
    // const [clientTypes, setclientTypes] = useState()
    const [workingCenter, setworkingCenter] = useState();
    const [gender, setGender] = useState()
    const [dependencyDegree, setDependencyDegree] = useState()
    const [therapeuticProfile, setTherapeuticProfile] = useState()
    const [technologicalIndependenceList, setTechnologicalIndependenceList] = useState();
    const [usesTechHelpList, setUsesTechHelpList] = useState();
    const classes = TabsUseStyles();
    const { identity, loading } = useGetIdentity();
    const [useAerial, setUseAerial] = useState();
    const [civilStatus, setCivilStatus] = useState();
    const [profession, setProfession] = useState();
    const [postalCode ] = useState('');
    const { permissions } = usePermissions();

    useEffect(() => {
        console?.log('Loading user form -> Provinces');
        
        getCountries()?.then(
            res => {
                setcountriesList(res?.elements);
            }
        )
        getLanguajes()?.then(
            res => {
                setlanguajeList(res?.elements);
            }
        )
        getLikes()?.then(
            res => {
                setlikeList(res?.elements);
            }
        )
        // getClientType()?.then(
        //     res => {
        //         setclientTypes(res.elements);
        //     }
        // )
        getWorkingCenter()?.then(
            res => {
                setworkingCenter(res?.elements);
            }
        )
        getGender()?.then(
            res => {
                setGender(res?.elements);
            }
        )
        getDependencyDegree()?.then(
            res => {
                setDependencyDegree(res?.elements);
            }
        )
        getTherapeuticProfile()?.then(
            res => {
                setTherapeuticProfile(res?.elements);
            }
        )
        getTechnologicalIndependence()?.then(
            res => {
            setTechnologicalIndependenceList(res?.elements);
            }
        )
        getUsesTechHelp()?.then(
            res => {
            setUsesTechHelpList(res?.elements);
            }
        )
        getCivilStatus().then(
            res => {
                setCivilStatus(res.elements);
            }
        )
        getProfession().then(
            res => {
                setProfession(res.elements);
            }
        )
    }, []);

    const handleSetProvinceList = (data) => {
        if (data) {
            setprovinceList(data);
        }
    }

    if (loading && !identity) {
        return <div></div>;
    }

    return (
        <TabbedForm
            toolbar={null}
            classes={classes}
            className="userTabs"
            style={{ minHeight: "33rem" }}
            validate={mode === 'create' ? validateUserCreation : validateUserEdit}
        >
            <FormTab label="Usuario" contentClassName="tab-container">
                <TextInput label="NIF/NIE" source="identificationNumber" disabled />
                <TextInput label="Email" source="email" validate={[email()]} disabled />
                <TextInput label="Nombre" source="name" disabled />
                <TextInput label="Apellidos" source="surnames" disabled />
                <TextInput label="Alias" source="alias" disabled/>
                
                {
                    languajeList ?
                    <ReadOnlyMultiSelect valuesList={languajeList} label="Idioma" property="languages"/>
                    : null
                }
                {/* {
                clientTypes ? 
                    <AutocompleteInput 
                        label="Tipo usuario"
                        source="clientTypeId" 
                        choices={clientTypes} 
                        optionText="value" 
                        optionValue="id"
                        options={{ InputProps: { autoComplete: 'off' } }}
                        resettable={true}
                    /> 
                    : null
            } */}
                {
                    likeList ?
                        <ReadOnlyMultiSelect valuesList={likeList} label="Gustos/Preferencias" property="clientLikes"/>
                        : null
                }
                <ReferenceInput
                    label="Psicólogo de referencia"
                    source="supportProfessional.id"
                    reference="professional"
                    // filter={{type: ['PROFESSIONAL']}}
                    validate={required()}
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                >
                    <AutocompleteInput translateChoice={false} optionText={(record) => {
                        if (!record) return null
                        return record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames
                    }} resettable={true} disabled />
                </ReferenceInput>
                <ReferenceInput
                    label="Trabajador Social de referencia"
                    source="referenceSocialWorker.id"
                    reference="professional"
                    filter={{ type: ['SOCIAL_WORKER'] }}
                    validate={required()}
                    filterToQuery={(value) => {
                        return { nameINumber: value }
                    }}
                    disabled
                >
                    <AutocompleteInput translateChoice={false} optionText={(record) => {
                        if (!record) return null
                        return record?.identificationNumber + ' - ' + record?.name + ' ' + record?.surnames
                    }} resettable={true} />
                </ReferenceInput>
                <div className="new-line">
                    <ReferenceInput
                        label="Dispositivo android"
                        source="androidDevice.id"
                        reference="devices"
                        filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                    >
                        <AutocompleteInput translateChoice={false} optionText={(record) => {
                            return record?.code
                        }} resettable={true} disabled />
                    </ReferenceInput>
                    <ReferenceInput
                        label="Dispositivo comunicación"
                        source="communicationDevice.id"
                        reference="comunicationDevices"
                        filter={{ "unassigned": true, "assigned": false, "isFailed": false }}
                    >
                        <AutocompleteInput translateChoice={false} optionText={(record) => {
                            return record?.code
                        }} resettable={true} disabled />
                    </ReferenceInput>
                </div>
                {
                    workingCenter ?
                        <AutocompleteInput
                            label="Centro de Trabajo"
                            source="associatedWorkingCenterId"
                            choices={workingCenter}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' }, autoComplete: 'off' }}
                            resettable={true}
                            disabled
                        />
                        : null
                }
                <div className="new-line">
                    <TextInput label="Versión app Vera" source="veraAppVersion" disabled={true} />
                    <DateTimeInput label="Fecha versión app Vera" source="veraAppVersionDate" disabled={true} />
                </div>
                <DateInput label="Fecha de Alta" source="activeDate" disabled={true} />
                <DateInput label="Fecha de Baja" source="leavingDate" disabled={true} />
                <DateInput label="Fecha Nacimiento" source="birthDate" disabled={true} />
                <DateInput label="Fecha de Instalación" source="setupDate" disabled={true} />
                {
                    gender ?
                        <AutocompleteInput
                            label="Sexo"
                            source="genderId"
                            choices={gender}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                    : null
                }
                {
                    civilStatus ?
                        <AutocompleteInput
                            label="Estado Civil"
                            source="civilStatusId"
                            choices={civilStatus}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                    : null
                }
                {
                    profession ?
                        <AutocompleteInput
                            label="Profesión"
                            source="professionId"
                            choices={profession}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                    : null
                }
                {
                    dependencyDegree ?
                        <AutocompleteInput
                            label="Grado Dependencia"
                            source="dependencyDegreeId"
                            choices={dependencyDegree}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                    : null
                }
                {
                    therapeuticProfile ?
                        <ReadOnlyMultiSelect valuesList={therapeuticProfile} label="Perfil Terapéutico" property="therapeuticalProfiles"/>
                    : null
                }
                {
                    technologicalIndependenceList ?
                        <AutocompleteInput
                            label="Autonomía a la conexión de la persona"
                            source="technologicalIndependenceId"
                            choices={technologicalIndependenceList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled={true}
                        />
                    : null
                }
                {
                    usesTechHelpList?
                        <div className="new-line">
                            <AutocompleteInput
                                label="¿Utiliza ayudas técnicas?"
                                source="usesTechHelpId"
                                choices={usesTechHelpList}
                                optionText="value"
                                optionValue="id"
                                options={{ InputProps: { autoComplete: 'off' } }}
                                resettable={true}
                                disabled
                            />
                            <TextInput style={{width: '72%'}} label="¿Cuáles?" source="whichOnesTechHelp" disabled />
                        </div>
                    : null
                }
                <div className="new-line">
                    <UseAerialChildSwitchAndSelect  setUseAerial={setUseAerial} useAerial={useAerial} identity={identity} isDisabled={true} />
                    {!useAerial && <span style={{width: '80%'}}></span>}
                </div>
                
            </FormTab>
            <FormTab label="Contacto" contentClassName="tab-container">
                <TextInput label="Teléfono Móvil" source="cellPhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled />
                <TextInput label="Teléfono fijo" source="homePhone" validate={[maxValue(999999999999, 'Debe contener 12 caracteres o menos'), number()]} disabled />
                {
                    countriesList ?
                        <AutocompleteInput
                            label="País"
                            source="countryId"
                            choices={countriesList}
                            optionText="value"
                            optionValue="id"
                            options={{ InputProps: { autoComplete: 'off' } }}
                            resettable={true}
                            disabled
                        />
                        : null
                }
                <TextInput label="Código Postal" source="postalCode" disabled />
                {
                    <AutocompleteInputTown onSetProvinceList={handleSetProvinceList}  postalCode={postalCode}{...props}  isDisabled={true}/>
                }
                {
                    <AutocompleteInputProvince provinceList={provinceList} {...props} isDisabled={true}/>         
                }
                <TextInput label="Dirección 1" source="address" disabled />
                <TextInput label="Dirección 2" source="address2" disabled />
            </FormTab>
            {
                mode !== 'create' ?
                    <FormTab label="Personas de contacto">
                        <ReferenceManyField
                            addLabel={false}
                            reference="contacts"
                            target="id"
                            sort={{ field: "created_at", order: "DESC" }}
                        >
                            <Datagrid>
                                <TextField label="Nombre" source="name" />
                                <TextField label="Apellidos" source="surnames" />
                                <TextField label="NIF/NIE" source="identificationNumber" />
                                <TextField label="Teléfono 1" source="phone1" />
                                <TextField label="Teléfono 2" source="phone2" />
                                <TextField label="Email" source="email" />
                                <ContactTypeField label="Tipo" source="typeId" />
                                {/* {
                                    props.permissions && props.permissions.contacts.edit ?
                                        <EditContactsButton /> : null
                                }
                                {
                                    props.permissions && props.permissions.contacts.edit ?
                                        <DeleteContactsButton /> : null
                                } */}
                            </Datagrid>
                        </ReferenceManyField>
                        {/* {
                            props.permissions && props.permissions.contacts.create ?
                                <AddNewContactsButton disabled /> : null
                        } */}
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Historia de usuario">
                        <UserHistoryView {...props}></UserHistoryView>
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== 'AAPP_TECHNICIAN' && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Interacciones de usuario">
                        <UserInteractionsList {...props}></UserInteractionsList>
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "AAPP_TECHNICIAN" && identity?.roles !== "LOGISTICS_TECHNICIAN" ?
                    <FormTab label="Documentos">
                        <UserDocumentsList {...props}></UserDocumentsList>
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && permissions?.contracts?.enabled ?
                    <FormTab label="Contratos" contentClassName="">
                        <ArrayInput label="Contratos" source="contracts" id="arrayCustom">
                            <SimpleFormIterator disableRemove disabledAdd addButton={<span></span>} >
                                <ReferenceInput label="Contrato" source="id" reference="contracts" disabled>
                                    <AutocompleteInput
                                        optionText="contractNumber"
                                        validate={[required()]}
                                    />
                                </ReferenceInput>
                                <FormDataConsumer>
                                    {({
                                        scopedFormData
                                    }) =>
                                    (
                                        scopedFormData?.id ? <BooleanInputCustom contractId={scopedFormData.id} source="isMain" {...props} isDisabled={true} /> : null
                                    )
                                    }
                                </FormDataConsumer>
                            </SimpleFormIterator>
                        </ArrayInput>
                        <TextInput style={{ display: 'none' }} source="contractsValidation" />
                    </FormTab>
                    : null
            }
            {
                mode !== 'create' && identity?.roles !== "AAPP_TECHNICIAN" ?
                    <FormTab label="Plano Vivienda">
                        <HousingPlanDocumentsList {...props}></HousingPlanDocumentsList>
                    </FormTab>
                    : null
            }
            <FormTab label="Aerial" id="aerialtab">
                <AerialTab {...props}></AerialTab>
            </FormTab>
        </TabbedForm>
    )
}

const transformUser = (data) => {

    // Transform UserHistory
    data.userHistory = data?.userHistory ? transformUserHistory(data?.userHistory) : null

    // Pasamos fechas a formato UTC Z
    const activeDate = data?.activeDate ? moment(data?.activeDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;
    const leavingDate = data?.leavingDate ? moment(data?.leavingDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;
    const inactivityDate = data?.inactivityDate ? moment(data?.inactivityDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;
    const birthDate = data?.birthDate ? moment(data?.birthDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;
    const setupDate = data?.setupDate ? moment(data?.setupDate)?.format('yyyy-MM-DDTHH:mm:ss') : null;

    return {
        ...data,
        supportProfessionalId: data?.supportProfessional?.id,
        clientExternalPartnersId: data?.clientExternalPartners?.id,
        referenceSocialWorkerProfessionalId: data?.referenceSocialWorker?.id,
        androidDeviceID: data?.androidDevice?.id,
        communicationDeviceID: data?.communicationDevice?.id,
        contracts: data?.contracts?.map(c => c?.id),
        activeDate,
        leavingDate,
        inactivityDate,
        birthDate,
        setupDate
    }
}
